import { MainFlowRouteNames, RemainingRouteNames, TRouteNames } from '@/types/i-router';
import store from '@/store';

class InitialRouteHandler {
	public setInitialRoute(suggestedRoute: string): void {
		const routeToShow = this.determineRouteToShow(suggestedRoute);
		if (!routeToShow) {
			return;
		}

		store.mutations.setCurrentRoute(routeToShow);
	}

	private determineRouteToShow(suggestedRoute: string): TRouteNames | undefined {
		const mainFlowRoutes: string[] = Object.values(MainFlowRouteNames);
		const remainingFlowRoutes: string[] = Object.values(RemainingRouteNames);
		const allRoutes = mainFlowRoutes.concat(remainingFlowRoutes);
		if (!allRoutes.includes(suggestedRoute)) {
			// Invalid route name, so use the default route instead.
			return;
		}

		return suggestedRoute as TRouteNames;
	}
}

export default new InitialRouteHandler();
