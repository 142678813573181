import { Component, defineComponent, toRef, Ref } from 'vue';
import { Premium } from '@/types/premiums';
import PremiumLabel from '@/presentation/components/premium-label/premium-label.vue';

export default defineComponent({
	components: {
		PremiumLabel: PremiumLabel as Component
	},
	props: {
		count: { type: Number, default: 0 },
		level: { type: Object, default: {} }
	},
	setup: (props) => {
		const count = toRef(props, 'count');
		const level = toRef(props, 'level') as Ref<Premium>;

		return {
			count,
			level
		};
	}
});
