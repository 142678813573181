import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "ExistingPresetBasedSelection",
  class: "ConfigurationBlockList WhiteBackground"
}
const _hoisted_2 = { class: "ExistingPresetBasedSelectionBox" }
const _hoisted_3 = ["onClick", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryTargetingSummary = _resolveComponent("CountryTargetingSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesPerPremiumGroups, (group, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("label", _hoisted_2, [
          _createElementVNode("input", {
            type: "radio",
            name: "existingPresetSelection",
            class: "ExistingPresetBasedSelectionRadioButton",
            onClick: ($event: any) => (_ctx.setSelectedPresetIndex(index)),
            checked: index === 0
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createVNode(_component_CountryTargetingSummary, {
          id: index,
          tabSelected: _ctx.selectedTab === index,
          numCountriesPerPremium: group.numCountriesPerPremium,
          countriesPerPremium: group.countriesPerPremium,
          pricing: group.pricing,
          onTabSelectionChanged: ($event: any) => (_ctx.toggleTab(index))
        }, null, 8 /* PROPS */, ["id", "tabSelected", "numCountriesPerPremium", "countriesPerPremium", "pricing", "onTabSelectionChanged"])
      ], 64 /* STABLE_FRAGMENT */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}