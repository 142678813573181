import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "PremiumLabelWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumLabel = _resolveComponent("PremiumLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PremiumLabel, {
      premium: _ctx.level,
      class: "PremiumLabel"
    }, null, 8 /* PROPS */, ["premium"]),
    _createElementVNode("span", {
      class: _normalizeClass(["CountryIndicator", { NoCountries: _ctx.count === 0 }])
    }, _toDisplayString(_ctx.count), 3 /* TEXT, CLASS */)
  ]))
}