/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
import { defineComponent, onMounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import EntitySelectionFiltersComponent from '@/presentation/components/entity-selection-filters/entity-selection-filters-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: (props, context) => {
		const component = new EntitySelectionFiltersComponent(context.emit);

		onMounted(component.mounted.bind(component));

		return {
			entitySelectionPricingFilter: component.entitySelectionPricingFilter,
			entitySelectionDegreeFilter: component.entitySelectionDegreeFilter,
			pricingFilterOptions: component.pricingFilterOptions,
			degreeFilterOptions: component.degreeFilterOptions,
			selectPricingFilterOption: component.selectPricingFilterOption.bind(component),
			selectDegreeFilterOption: component.selectDegreeFilterOption.bind(component)
		};
	}
});
