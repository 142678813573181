import { defineComponent } from 'vue';
import FinalComponent from '@/presentation/pages/final/final/final-class';

export default defineComponent({
	setup: () => {
		const component = new FinalComponent();

		return {
			finalClassName: component.finalClassName
		};
	}
});
