import { Component, defineComponent } from 'vue';
import OverviewListComponent from '@/presentation/components/overview-list/overview-list-class';
import CountriesPerLevel from '@/presentation/components/countries-per-level/countries-per-level.vue';
import LevelWithIndicator from '@/presentation/components/level-with-indicator/level-with-indicator.vue';

export default defineComponent({
	components: {
		CountriesPerLevel: CountriesPerLevel as Component,
		LevelWithIndicator: LevelWithIndicator as Component
	},
	setup: () => {
		const component = new OverviewListComponent();

		return {
			premiumStates: component.premiumStates,
			countriesPerPremium: component.countriesPerPremium,
			numCountriesPerPremium: component.numCountriesPerPremium
		};
	}
});
