import { Component, defineComponent } from 'vue';
import CountryStateControlComponent from '@/presentation/components/country-state-control/country-state-control-class';
import SelectedCountryCounter from '@/presentation/components/selected-country-counter/selected-country-counter.vue';
import SetSelectedCountryLevel from '@/presentation/components/set-selected-country-level/set-selected-country-level.vue';

export default defineComponent({
	components: {
		SelectedCountryCounter: SelectedCountryCounter as Component,
		SetSelectedCountryLevel: SetSelectedCountryLevel as Component
	},
	setup: () => {
		const component = new CountryStateControlComponent();

		return {
			numCheckedCountries: component.numCheckedCountries,
			doSelectedEntitiesHaveTheSameTargeting: component.doSelectedEntitiesHaveTheSameTargeting
		};
	}
});
