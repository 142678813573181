import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "country-selection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContinentIndicator = _resolveComponent("ContinentIndicator")!
  const _component_SearchBarCountries = _resolveComponent("SearchBarCountries")!
  const _component_CountryList = _resolveComponent("CountryList")!
  const _component_Shortcuts = _resolveComponent("Shortcuts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ContinentIndicator),
    _createVNode(_component_SearchBarCountries),
    _createVNode(_component_CountryList, { class: "CountryList" }),
    _createVNode(_component_Shortcuts, {
      shortcuts: _ctx.additionShortcuts,
      class: "Shortcuts"
    }, null, 8 /* PROPS */, ["shortcuts"])
  ]))
}