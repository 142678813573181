import { Component, computed, ref } from 'vue';
import { TRoutes, MainFlowRouteNames, RemainingRouteNames, IRouter } from '@/types/i-router';
import { EntityTypes } from '@/types/entities';
import store from '@/store';

import EntitySelection from '@/presentation/pages/entity-selection/entity-selection.vue';
import CountryManagement from '@/presentation/pages/country-management/country-management.vue';
import PricingSelection from '@/presentation/pages/pricing-selection/pricing-selection.vue';
import Overview from '@/presentation/pages/overview/overview.vue';
import Success from '@/presentation/pages/final/success/success.vue';
import Error from '@/presentation/pages/final/error/error.vue';
import Exit from '@/presentation/pages/final/exit/exit.vue';
import StudyListingOverview from '@/presentation/pages/listing/study-listing-overview/study-listing-overview.vue';
import OrganisationListingOverview from '@/presentation/pages/listing/organisation-listing-overview/organisation-listing-overview.vue';
import ExistingPresetBasedSelection from '@/presentation/pages/existing-preset-based-selection/existing-preset-based-selection.vue';
import Default from '@/presentation/components/default/default.vue';

export default class RouterViewComponent {
	public readyToBeShown = ref(false);
	public transitionName = ref('fade');

	private get mainFlowRouteNames(): string[] {
		return Object.values(MainFlowRouteNames);
	}

	private routes: TRoutes = {
		[MainFlowRouteNames.ENTITY_SELECTION]: EntitySelection as Component,
		[MainFlowRouteNames.MANAGEMENT]: CountryManagement as Component,
		[MainFlowRouteNames.PRICING_SELECTION]: PricingSelection as Component,
		[MainFlowRouteNames.OVERVIEW]: Overview as Component,
		[RemainingRouteNames.FINAL_SUCCESS]: Success as Component,
		[RemainingRouteNames.FINAL_ERROR]: Error as Component,
		[RemainingRouteNames.FINAL_EXIT]: Exit as Component,
		[RemainingRouteNames.STUDY_LISTING_OVERVIEW]: StudyListingOverview as Component,
		[RemainingRouteNames.ORGANISATION_LISTING_OVERVIEW]: OrganisationListingOverview as Component,
		[RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION]: ExistingPresetBasedSelection as Component,
		[RemainingRouteNames.DEFAULT]: Default as Component
	};

	public router = computed((): IRouter => {
		return store.getters.router();
	});

	public currentRoute = computed((): string => {
		return this.router.value.current;
	});

	public initialised = computed((): boolean => {
		return store.getters.initialised();
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public CurrentComponent = computed((): Component => {
		return this.routes[this.router.value.current];
	});

	private isPresetSelectionScreen = computed((): boolean => {
		return this.currentRoute.value === (RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION as string);
	});

	private routeIsListing = computed((): boolean => {
		const listingRoutes: string[] = [
			RemainingRouteNames.ORGANISATION_LISTING_OVERVIEW,
			RemainingRouteNames.STUDY_LISTING_OVERVIEW
		];
		return listingRoutes.includes(this.currentRoute.value);
	});

	public mounted(): void {
		void this.triggerDataRetrievalDependingOnContext().then(() => {
			store.mutations.markAsInitialised();
			this.readyToBeShown.value = true;
		});
	}

	private async triggerDataRetrievalDependingOnContext(): Promise<void> {
		if (this.routeIsListing.value) {
			if (this.currentRoute.value === RemainingRouteNames.STUDY_LISTING_OVERVIEW as string) {
				await store.actions.getEntityData(EntityTypes.STUDY);
			} else {
				await store.actions.getEntityData(EntityTypes.ORGANISATION);
			}
		} else {
			await store.actions.getCampaignData(false);
		}
	}

	public setTransitionName(to: string, from: string): void {
		if (this.isRoutingGoingBackFromPresetSelectionScreen(to, from)) {
			this.transitionName.value = 'slide-right';
			return;
		}

		if (this.isRoutingTakingPlaceOutsideMainFlow(to, from)) {
			this.transitionName.value = `slide-${from === (RemainingRouteNames.FINAL_EXIT as string) ? 'right' : 'left'}`;
			return;
		}

		const toIndex = this.mainFlowRouteNames.indexOf(to);
		const fromIndex = this.mainFlowRouteNames.indexOf(from);
		if (toIndex !== -1 && toIndex < fromIndex) {
			this.transitionName.value = 'slide-right';
		} else if (fromIndex !== -1 && toIndex > fromIndex) {
			this.transitionName.value = 'slide-left';
		} else {
			this.transitionName.value = 'fade';
		}
	}

	private isRoutingTakingPlaceOutsideMainFlow(to: string, from: string): boolean {
		if (from === (RemainingRouteNames.DEFAULT as string)) {
			return false;
		}

		return !this.mainFlowRouteNames.includes(to) || !this.mainFlowRouteNames.includes(from);
	}

	private isRoutingGoingBackFromPresetSelectionScreen(to: string, from: string): boolean {
		return (
			to === (MainFlowRouteNames.ENTITY_SELECTION as string) &&
			from === (RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION as string)
		);
	}
}
