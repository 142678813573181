import { computed, ref } from 'vue';
import { MainFlowRouteNames, RemainingRouteNames } from '@/types/i-router';
import { ILoading, LoadingStates } from '@/types/i-loading';
import OpenCloseAppHandler from '@/plugins/open-close-app-handler';
import store from '@/store';

export default class AppComponent {
	public transitionName = ref('fade');
	private openAppBoundFunction: () => void;
	private closeAppBoundFunction: () => void;
	private removeEventListenersBoundFunction: () => void;

	private get mainFlowRouteNames(): string[] {
		return Object.values(MainFlowRouteNames);
	}

	public layoutElementStyling = computed((): string => {
		return `visibility: ${this.shouldPageLayoutElementsBeShown.value ? 'visible' : 'hidden'}`;
	});

	public routeIsListing = computed((): boolean => {
		const listingRoutes: string[] = [
			RemainingRouteNames.ORGANISATION_LISTING_OVERVIEW,
			RemainingRouteNames.STUDY_LISTING_OVERVIEW
		];
		return listingRoutes.includes(this.currentRoute.value);
	});

	public currentRoute = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});

	public loadingInProgress = computed((): boolean => {
		return this.loading.value.state !== LoadingStates.INACTIVE;
	});

	private loading = computed((): ILoading => {
		return store.getters.loading();
	});

	private shouldPageLayoutElementsBeShown = computed((): boolean => {
		return (
			this.mainFlowRouteNames.includes(this.currentRoute.value) ||
			this.currentRoute.value === (RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION as string)
		);
	});

	public beforeMount(): void {
		if (this.routeIsListing.value) {
			store.mutations.setLoadingState(LoadingStates.INACTIVE);
			return;
		}

		this.openAppBoundFunction = OpenCloseAppHandler.openApp.bind(this);
		this.closeAppBoundFunction = OpenCloseAppHandler.closeApp.bind(this, true);
		this.removeEventListenersBoundFunction = this.removeEventListeners.bind(this);

		document.addEventListener('start_geo_targeting', this.openAppBoundFunction, { once: true });
		document.addEventListener('user_close_geo_targeting', this.closeAppBoundFunction, { once: true });
		document.addEventListener('destroy_geo_targeting', this.removeEventListenersBoundFunction, { once: true });
	}

	private removeEventListeners(): void {
		document.removeEventListener('start_geo_targeting', this.openAppBoundFunction);
		document.removeEventListener('user_close_geo_targeting', this.closeAppBoundFunction);
		document.removeEventListener('destroy_geo_targeting', this.removeEventListenersBoundFunction);
	}
}
