/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { defineComponent, onMounted, onUnmounted } from 'vue';
import LoadingAnimationComponent from '@/presentation/components/loading-animation/loading-animation-class';

export default defineComponent({
	setup: () => {
		const component = new LoadingAnimationComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			progress: component.progress,
			loadingText: component.loadingText,
			savingInProgress: component.savingInProgress
		};
	}
});
