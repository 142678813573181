import { reactive } from 'vue';
import countries from '@/api/countries.json';
import continents from '@/api/continents.json';
import regions from '@/api/regions.json';
import premiumStates from '@/api/premium-states.json';
import { IState } from '@/types/store/i-store';
import { RemainingRouteNames } from '@/types/i-router';
import { LoadingStates } from '@/types/i-loading';

const state: any = reactive({
	apiUrl: webpackDefinitions.API_URL,
	uniAdminUrl: webpackDefinitions.UNI_ADMIN_URL,
	schedulerUrl: webpackDefinitions.SCHEDULER_URL,
	serviceLayerRelayServiceUrl: webpackDefinitions.SERVICE_LAYER_RELAY_SERVICE_URL,
	campaignManagementAPIUrl: webpackDefinitions.CAMPAIGN_MANAGEMENT_API_URl,
	cognito: {
		clientId: webpackDefinitions.COGNITO_CLIENT_ID,
		poolId: webpackDefinitions.COGNITO_POOL_ID,
		domain: webpackDefinitions.COGNITO_DOMAIN
	},
	searchQueryCountries: '',
	selectedProgrammes: [],
	detailsOfAllEntities: [],
	detailsOfSelectedEntities: [],
	selectedPresetIndex: -1,
	postData: null,
	campaignId: null,
	studyId: null,
	organisationId: null,
	originalData: null,
	countries,
	continents,
	regions,
	premiumStates,
	pricingProducts: [],
	campaignEntityConfigItems: [],
	campaignStartDate: null,
	loading: {
		state: LoadingStates.INACTIVE,
		estimatedTimeNeededForSaving: 0
	},
	router: {
		current: RemainingRouteNames.DEFAULT,
		props: []
	},
	initialised: false
});

export default state as IState;
