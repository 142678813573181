/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, defineComponent, onMounted } from 'vue';
import OrganisationListingOverviewComponent
	from '@/presentation/pages/listing/organisation-listing-overview/organisation-listing-overview-class';
import ListingOverview from '@/presentation/components/listing-overview/listing-overview.vue';

export default defineComponent({
	components: {
		ListingOverview: ListingOverview as Component
	},
	setup: () => {
		const component = new OrganisationListingOverviewComponent();

		onMounted(component.mounted.bind(component));

		return {
			campaignId: component.campaignId
		};
	}
});
