import { nonReferralPricingProducts } from '@/static/details-of-specific-pricing-products';
import { PostData, PostDataWithIdsAsStrings, EntityWithIdAsString, PreProcessedEntity } from '@/types/postData';
import { ScheduledGeoCampaignResult } from '@/types/i-request-results';
import { SchedulerRequestPayload } from '@/types/scheduler';
import { EntityTypes } from '@/types/entities';
import { CampaignEntityConfigItemScheduler, SchedulerRawAttachedPricingProductsData } from '@/types/pricing';
import CookieHandler from '@/plugins/cookie-handler';
import store from '@/store';

class SchedulerRequestsHandler {
	private get baseUrl(): string {
		return store.getters.schedulerUrl();
	}

	private get campaignId(): string {
		const id = store.getters.campaignId();
		return id.toString();
	}

	public async updateTargetingSettingsInScheduler(postData: PostData): Promise<void> {
		const body = { scheduledGeoCampaign: postData } as SchedulerRequestPayload;
		body.scheduledGeoCampaign.status = 'Start';

		const options = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(
			`${this.baseUrl}/update`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while saving country targeting using the Scheduler.');
		}
	}

	public async retrieveTargetingSettingsFromScheduler(): Promise<PostDataWithIdsAsStrings> {
		const options = {
			method: 'GET',
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(
			`${this.baseUrl}/retrieve-start-by-campaign-id?campaignID=${this.campaignId}`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while retrieving campaign settings using the Scheduler.');
		}

		const result = (await response.json()) as ScheduledGeoCampaignResult;
		const studies = this.convertFormatOfEntities(result.scheduledGeoCampaign.studies);
		const organisations = this.convertFormatOfEntities(result.scheduledGeoCampaign.organisations);
		return {
			campaign_id: parseInt(result.scheduledGeoCampaign.campaignID),
			expiry_date: result.scheduledGeoCampaign.expiryDate,
			update_date: result.scheduledGeoCampaign.updateDate,
			studies,
			organisations
		};
	}

	public async retrieveRawAttachedPricingProductsData(): Promise<SchedulerRawAttachedPricingProductsData> {
		const options = {
			method: 'GET',
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(
			`${this.baseUrl}/pricing?campaignID=${this.campaignId}`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while retrieving pricing data using the Scheduler.');
		}

		return (await response.json()) as SchedulerRawAttachedPricingProductsData;
	}

	public async savePricingProducts(): Promise<void> {
		const body = {
			campaignID: this.campaignId,
			entityConfigs: []
		};

		body.entityConfigs = body.entityConfigs.concat(this.constructPricingProductsForEntity(EntityTypes.STUDY));
		body.entityConfigs = body.entityConfigs.concat(this.constructPricingProductsForEntity(EntityTypes.ORGANISATION));

		const tokenId = CookieHandler.retrieveTokenIdCookie();
		const options = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: tokenId
			}
		};

		const response = await fetch(
			`${this.baseUrl}/pricing`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while saving pricing data using the Scheduler.');
		}
	}

	public shouldChangesGoThroughScheduler(): boolean {
		const startDate = store.state.campaignStartDate;
		return startDate > Date.now() / 1000;
	}

	private convertFormatOfEntities(entities: PreProcessedEntity[]): EntityWithIdAsString[] {
		return entities.map((entity) => ({
			id: entity.id,
			targeted_countries: entity.targetedCountries
		}));
	}

	private constructPricingProductsForEntity(type: EntityTypes): CampaignEntityConfigItemScheduler[] {
		const detailsOfEntities = store.getters.detailsOfAllEntities();
		const configurationOfEntities =
			type === EntityTypes.STUDY ? store.getters.configurationOfAllStudies() : store.getters.configurationOfAllOrganisations();
		if (configurationOfEntities.length <= 0) {
			return [];
		}

		const entityConfigs: CampaignEntityConfigItemScheduler[] = [];

		for (const configuration of configurationOfEntities) {
			if (!configuration.pricing) {
				continue;
			}

			const productIds = nonReferralPricingProducts.slice();
			productIds.push(configuration.pricing);
			const details = detailsOfEntities.find(
				(entity) => entity.id === configuration.id && entity.type === type
			);
			if (!details) {
				continue;
			}

			for (const productId of productIds) {
				entityConfigs.push({
					entityID: configuration.id.toString(),
					productID: productId,
					entityType: details.type
				});
			}
		}

		return entityConfigs;
	}
}

export default new SchedulerRequestsHandler();
