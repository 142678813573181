import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "OverviewList" }
const _hoisted_2 = { class: "LevelWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelWithIndicator = _resolveComponent("LevelWithIndicator")!
  const _component_CountriesPerLevel = _resolveComponent("CountriesPerLevel")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.premiumStates, (premium, level) => {
      return (_openBlock(), _createElementBlock("li", {
        key: level,
        class: "PremiumRow"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LevelWithIndicator, {
            level: premium,
            count: _ctx.numCountriesPerPremium[level] || 0
          }, null, 8 /* PROPS */, ["level", "count"])
        ]),
        _createVNode(_component_CountriesPerLevel, {
          countries: _ctx.countriesPerPremium[level]
        }, null, 8 /* PROPS */, ["countries"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}