import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ManageGeoContainer" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewList = _resolveComponent("OverviewList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_OverviewList, { class: "OverviewList" }),
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("a", {
        class: "ManageGeo",
        href: _ctx.campaignEditorUrl
      }, " Manage geotargeting for this campaign ", 8 /* PROPS */, _hoisted_2)
    ])
  ]))
}