import { AuditorCampaignManagementAPIClient as ImportedAuditorCampaignManagementAPIClient }
	from '@studyportals/campaign-management-api-interface';
import { AuditorOperation } from '@studyportals/campaign-management-api-interface/src/domain/auditor/enums/auditor-operation.enum';
import { IParsedJwt } from '@/types/i-jwt';
import { ICampaignAuditorData } from '@studyportals/campaign-management-api-interface/src/domain/auditor/campaigns/geotargeting.entities';
import store from '../store';
import cookieHandler from './cookie-handler';

class AuditorCampaignManagementAPIClient {
	private client: ImportedAuditorCampaignManagementAPIClient;

	public async storeCampaignDataInAuditor(isUserAction: boolean, data: ICampaignAuditorData): Promise<void> {
		this.client = new ImportedAuditorCampaignManagementAPIClient(store.getters.campaignManagementAPIUrl());

		const campaignID = store.getters.campaignId();
		const jwt = cookieHandler.retrieveTokenIdCookie();

		const username = this.parseJwt(jwt)['cognito:username'];

		await this.client.storeCampaignAuditorData(
			AuditorOperation.UPDATE,
			campaignID,
			username,
			isUserAction,
			data,
			jwt
		);
	}

	private parseJwt(token: string): IParsedJwt {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map((c) => {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(jsonPayload) as IParsedJwt;
	}
}

export default new AuditorCampaignManagementAPIClient();
