/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineComponent } from 'vue';
import SearchBarCountriesComponent from '@/presentation/components/search-bar-countries/search-bar-countries-class';

export default defineComponent({
	setup: () => {
		const component = new SearchBarCountriesComponent();

		return {
			searchBar: component.searchBar,
			isRevealed: component.isRevealed,
			isExpanded: component.isExpanded,
			isActive: component.isActive,
			updateSearchQuery: component.updateSearchQuery.bind(component),
			expandSearchBar: component.expandSearchBar.bind(component),
			hideSearchBar: component.hideSearchBar.bind(component),
			clearSearchQuery: component.clearSearchQuery.bind(component)
		};
	}
});
