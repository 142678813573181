import store from '@/store';
import { EntityTypes } from '@/types/entities';

export default class OrganisationListingOverviewComponent {
	public get campaignId(): number {
		return store.getters.campaignId();
	}

	private get organisationId(): number {
		return store.getters.organisationId();
	}

	public mounted(): void {
		store.mutations.markEntityAsSelected({
			type: EntityTypes.ORGANISATION,
			id: this.organisationId,
			title: '',
			degreeType: '',
			degreeName: '',
			targetingGroup: 0,
			pricingGroup: 0
		});
	}
}
