import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "SelectedCountryCounter",
  class: "SelectionDetailsCounterBlock"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["SelectionDetailsCounterNumber", { NoSelection: _ctx.numCheckedCountries === 0 }])
    }, _toDisplayString(_ctx.numCheckedCountries), 3 /* TEXT, CLASS */),
    (_ctx.numCheckedCountries > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " countries selected to be targeted as: "))
      : _createCommentVNode("v-if", true),
    (_ctx.numCheckedCountries === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " countries selected to be geotargeted. "))
      : _createCommentVNode("v-if", true)
  ]))
}