import { computed } from 'vue';
import { RemainingRouteNames } from '@/types/i-router';
import store from '@/store';

export default class FinalComponent {
	public finalClassName = computed((): string => {
		switch (this.currentRoute.value as RemainingRouteNames) {
			case RemainingRouteNames.FINAL_SUCCESS:
				return 'FinalSuccessPage';
			case RemainingRouteNames.FINAL_EXIT:
				return 'FinalExitPage';
			case RemainingRouteNames.FINAL_ERROR:
				return 'FinalErrorPage';
			default:
				return '';
		}
	});

	private currentRoute = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});
}
