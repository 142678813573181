/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent, onMounted } from 'vue';
import CountryTargetingSummary from '@/presentation/components/country-targeting-summary/country-targeting-summary.vue';
import ExistingPresetBasedSelectionComponent from '@/presentation/pages/existing-preset-based-selection/existing-preset-based-selection-class';

export default defineComponent({
	components: {
		CountryTargetingSummary: CountryTargetingSummary as Component
	},
	setup: () => {
		const component = new ExistingPresetBasedSelectionComponent();

		onMounted(component.mounted.bind(component));

		return {
			selectedTab: component.selectedTab,
			selectedPresetIndex: component.selectedPresetIndex,
			countriesPerPremiumGroups: component.countriesPerPremiumGroups,
			setSelectedPresetIndex: component.setSelectedPresetIndex.bind(component),
			toggleTab: component.toggleTab.bind(component)
		};
	}
});
