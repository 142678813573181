import { Component, defineComponent } from 'vue';
import CountryManagementComponent from '@/presentation/pages/country-management/country-management-class';
import CountrySelection from '@/presentation/components/country-selection/country-selection.vue';
import CountryStateControl from '@/presentation/components/country-state-control/country-state-control.vue';
import Shortcuts from '@/presentation/components/shortcuts/shortcuts.vue';

export default defineComponent({
	components: {
		CountrySelection: CountrySelection as Component,
		CountryStateControl: CountryStateControl as Component,
		Shortcuts: Shortcuts as Component
	},
	setup: () => {
		const component = new CountryManagementComponent();

		return {
			removalShortcuts: component.removalShortcuts
		};
	}
});
