/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent } from 'vue';
import ExitComponent from '@/presentation/pages/final/exit/exit-class';
import Final from '@/presentation/pages/final/final/final.vue';

export default defineComponent({
	components: {
		Final: Final as Component
	},
	setup: () => {
		const component = new ExitComponent();

		return {
			closeApp: component.closeApp.bind(component),
			goBack: component.goBack.bind(component)
		};
	}
});
