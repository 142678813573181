/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineComponent } from 'vue';
import CloseComponent from '@/presentation/components/close/close-class';

export default defineComponent({
	setup: () => {
		const component = new CloseComponent();

		return {
			closeApp: component.closeApp.bind(component)
		};
	}
});
