import { computed } from 'vue';
import { MainFlowRouteNames, RemainingRouteNames } from '@/types/i-router';
import { EntityDetails, EntityTypes } from '@/types/entities';
import store from '@/store';

export default class HeaderComponent {
	public isRouteOverview = computed((): boolean => {
		return this.currentRoute.value === MainFlowRouteNames.OVERVIEW;
	});

	public title = computed((): string => {
		switch (this.currentRoute.value) {
			case MainFlowRouteNames.ENTITY_SELECTION:
				return 'Select organisations and studies';
			case MainFlowRouteNames.MANAGEMENT:
				return 'Target countries';
			case MainFlowRouteNames.PRICING_SELECTION:
				return 'Select a pricing product';
			case MainFlowRouteNames.OVERVIEW:
				return 'Review settings';
			case RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION:
				return 'Select a preset';
			default:
				return 'Geotargeting';
		}
	});

	public subtitle = computed((): string => {
		switch (this.currentRoute.value) {
			case MainFlowRouteNames.ENTITY_SELECTION:
				return this.subtitleForEntitySelectionPage.value;
			default:
				return this.subtitleForTargetingPages.value;
		}
	});

	private currentRoute = computed((): MainFlowRouteNames | RemainingRouteNames => {
		const router = store.getters.router();
		return router.current as MainFlowRouteNames;
	});

	private detailsOfSelectedEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfSelectedEntities();
	});

	private numSelectedProgrammes = computed((): number => {
		return store.getters.numSelectedProgrammes();
	});

	private numSelectedOrganisations = computed((): number => {
		return store.getters.numSelectedOrganisations();
	});

	private subtitleForEntitySelectionPage = computed(() => {
		const organisationsText = `${this.numSelectedOrganisations.value} ${
			this.numSelectedOrganisations.value === 1 ? 'organisation' : 'organisations'
		}`;
		const studiesText = `${this.numSelectedProgrammes.value} ${this.numSelectedProgrammes.value === 1 ? 'study' : 'studies'}`;
		const attachedText =
			'are attached to this campaign. Please select for which of them you would like to view or edit the targeting per country.';
		return `${organisationsText} and ${studiesText} ${attachedText}`;
	});

	private subtitleForTargetingPages = computed((): string => {
		const amountOfOrganisations = this.detailsOfSelectedEntities.value.filter(
			(detailsOfEntity) => detailsOfEntity.type === EntityTypes.ORGANISATION
		).length;
		const amountOfStudies = this.detailsOfSelectedEntities.value.filter(
			(detailsOfEntity) => detailsOfEntity.type === EntityTypes.STUDY
		).length;
		const organisationsText = `${amountOfOrganisations} selected ${amountOfOrganisations === 1 ? 'organisation' : 'organisations'}`;
		const studiesText = `${amountOfStudies} selected ${amountOfStudies === 1 ? 'study' : 'studies'}`;
		return `The selected configuration will apply for the ${organisationsText} and ${studiesText} for all (applicable) Portals.`;
	});
}
