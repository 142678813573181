import { Entity, PostData, TargetedCountries } from '@/types/postData';
import { EntityDetails, EntityTypes } from '@/types/entities';

class EntityDetailsHandler {
	public targetingGroups: TargetedCountries[] = [];
	public pricingGroups: string[] = [];

	public retrieveSelectedEntity(postData: PostData, selectedEntity: EntityDetails): Entity | undefined {
		const relevantStudy = postData.studies.find(
			(study) => study.id === selectedEntity.id && selectedEntity.type === EntityTypes.STUDY
		);
		if (relevantStudy) {
			return relevantStudy;
		}

		const relevantOrganisation = postData.organisations.find(
			(organisation) => organisation.id === selectedEntity.id && selectedEntity.type === EntityTypes.ORGANISATION
		);
		if (relevantOrganisation) {
			return relevantOrganisation;
		}
	}

	public prepareDetailsForAllEntities(postData: PostData): EntityDetails[] {
		this.targetingGroups = [];
		this.pricingGroups = [];
		const studies = postData.studies.map((study) => this.prepareDetailsForSingleEntity(study, EntityTypes.STUDY));
		const organisations = postData.organisations.map((organisation) =>
			this.prepareDetailsForSingleEntity(organisation, EntityTypes.ORGANISATION)
		);

		return organisations.concat(studies);
	}

	private prepareDetailsForSingleEntity(entity: Entity, type: EntityTypes): EntityDetails {
		// TODO: Include entity titles in the response from the geotargeting back-end.
		const entityElement = document.querySelector(`#EntityList li[data-id="${entity.id}"]`);
		const entityName = document.querySelector(`#EntityList li[data-id="${entity.id}"] .Name`);
		const entityType = document.querySelector(`#EntityList li[data-id="${entity.id}"] .Type`);

		let degreeType = '';
		if (type === EntityTypes.ORGANISATION) {
			degreeType = type;
		} else if (entityElement) {
			degreeType = (entityElement as HTMLElement).dataset.type;
		}

		return {
			id: entity.id,
			type,
			degreeType,
			title: entityName ? entityName.innerHTML : '',
			degreeName: entityType ? entityType.innerHTML : '',
			targetingGroup: this.arrangeGroupForEntityTargeting(entity),
			pricingGroup: this.arrangeGroupForEntityPricing(entity)
		};
	}

	private arrangeGroupForEntityTargeting(entity: Entity): number {
		const indexOfExistingGroup = this.findIndexOfGroupThatMatchesEntityTargeting(entity);
		if (indexOfExistingGroup === -1) {
			const indexOfNewGroup = this.targetingGroups.length;
			this.targetingGroups.push(entity.targeted_countries);
			return indexOfNewGroup;
		}

		return indexOfExistingGroup;
	}

	private arrangeGroupForEntityPricing(entity: Entity): number {
		const indexOfExistingGroup = this.findIndexOfGroupThatMatchesEntityPricing(entity);
		if (indexOfExistingGroup === -1) {
			const indexOfNewGroup = this.pricingGroups.length;
			this.pricingGroups.push(entity.pricing);
			return indexOfNewGroup;
		}

		return indexOfExistingGroup;
	}

	private findIndexOfGroupThatMatchesEntityTargeting(entity: Entity): number {
		return this.targetingGroups.findIndex((group) => {
			for (const property in entity.targeted_countries) {
				const specificGroup = group[property] as number[];

				if (specificGroup.length !== entity.targeted_countries[property].length) {
					return false;
				}
				const deviatingCountry = specificGroup.find(
					(country, index) => country !== entity.targeted_countries[property][index]
				);

				if (deviatingCountry !== undefined) {
					return false;
				}
			}

			return true;
		});
	}

	private findIndexOfGroupThatMatchesEntityPricing(entity): number {
		return this.pricingGroups.findIndex((group) => {
			return group === entity.pricing;
		});
	}
}

export default new EntityDetailsHandler();
