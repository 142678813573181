import { computed } from 'vue';
import store from '@/store';

export default class CountryStateControlComponent {
	public numCheckedCountries = computed((): number => {
		return store.getters.numCheckedCountries();
	});

	public doSelectedEntitiesHaveTheSameTargeting = computed((): boolean => {
		return store.getters.doSelectedEntitiesHaveTheSameTargeting();
	});
}
