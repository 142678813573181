import { computed } from 'vue';
import { Premiums } from '@/types/premiums';
import store from '@/store';

export default class SetSelectedCountryLevelComponent {
	public premiumStates = computed((): Premiums => {
		return store.getters.premiumStates();
	});

	public setCheckedToPremiumLevel(level: string): void {
		store.actions.setCheckedToPremiumLevel(level);
	}
}
