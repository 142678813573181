/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineComponent, Ref, toRef } from 'vue';
import CountriesPerLevelComponent from '@/presentation/components/countries-per-level/countries-per-level-class';

export default defineComponent({
	props: {
		countries: { type: Array, default: [] }
	},
	setup: (props) => {
		const countries = toRef(props, 'countries') as Ref<number[]>;
		const component = new CountriesPerLevelComponent(countries);

		return {
			countries,
			showComma: component.showComma.bind(component)
		};
	}
});
