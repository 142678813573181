import { createApp, h } from 'vue';
import { LoadingStates } from '@/types/i-loading';
import store from '@/store';
import InitialRouteHandler from '@/plugins/initial-route-handler';
import RollbarOperator from '@/plugins/rollbar-operator';
import App from '@/presentation/app.vue';

declare global {
	interface Window {
		Rollbar?: {
			error: (message: Error | string) => void;
			warn: (message: Error | string) => void;
		};
	}
}

// Collect the currently set data attribute on the HTML element.
const rootElement: HTMLElement = document.getElementById('GeoTargetingMS');

// Present when we are on main screen in campaign editor.
const campaignId: number | null = parseInt(rootElement.dataset.campaignId, 10) || null;
const campaignStartDate: number | null = parseInt(rootElement.dataset.campaignStartDate, 10) || null;

// Present when we are in the study / organisation editor.
const startRoute: string = rootElement.dataset.startRoute || '';
const studyId: number | null = parseInt(rootElement.dataset.studyId, 10) || null;
const organisationId: number | null = parseInt(rootElement.dataset.organisationId, 10) || null;

store.mutations.setStudyId(studyId);
store.mutations.setOrganisationId(organisationId);
store.mutations.setCampaignId(campaignId);
store.mutations.setCampaignStartDate(campaignStartDate);
store.mutations.setLoadingState(LoadingStates.STARTING);

InitialRouteHandler.setInitialRoute(startRoute);

const app = createApp({
	render: () => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return h(App);
	}
});

RollbarOperator.handleVueErrors(app);

app.mount('#GeoTargetingMS');
