import { computed, Ref } from 'vue';
import { Country } from '@/types/locations';
import { Premium } from '@/types/premiums';
import store from '@/store';

export default class CountryComponent {
	constructor(
		public id: Ref<string>
	) { }

	public country = computed((): Country => {
		return store.state.countries[this.id.value];
	});

	public premium = computed((): Premium | object => {
		const premiumLevels = store.getters.countryPremiumLevels();
		return premiumLevels[this.id.value] || {};
	});

	public isChecked = computed((): boolean => {
		return this.country.value.checked;
	});

	public toggle(): void {
		store.mutations.setCountryChecked({ id: this.id.value, checked: !this.isChecked.value });
	}
}
