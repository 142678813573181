import { Ref } from 'vue';

export default class CountriesPerLevelComponent {
	constructor(
		public countries: Ref<number[]>
	) { }

	public showComma(index: number): boolean {
		return index < this.countries.value.length - 1;
	}
}
