enum ShortcutTypes {
	CONTINENT = 'continent',
	REGION = 'region',
	FILTER = 'filter'
}

interface Shortcut {
	type: ShortcutTypes | null;
	targetId: string | null;
	text: string;
	name: string;
	checked: boolean;
}

export { Shortcut, ShortcutTypes };
