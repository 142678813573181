import { Component, defineComponent } from 'vue';
import CountrySelectionComponent from '@/presentation/components/country-selection/country-selection-class';
import ContinentIndicator from '@/presentation/components/continent-indicator/continent-indicator.vue';
import CountryList from '@/presentation/components/country-list/country-list.vue';
import SearchBarCountries from '@/presentation/components/search-bar-countries/search-bar-countries.vue';
import Shortcuts from '@/presentation/components/shortcuts/shortcuts.vue';

export default defineComponent({
	components: {
		ContinentIndicator: ContinentIndicator as Component,
		CountryList: CountryList as Component,
		Shortcuts: Shortcuts as Component,
		SearchBarCountries: SearchBarCountries as Component
	},
	setup: () => {
		const component = new CountrySelectionComponent();

		return {
			additionShortcuts: component.additionShortcuts
		};
	}
});
