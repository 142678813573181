import state from './state';

import { IGetters } from '@/types/store/i-getters';
import { EntityTypes } from '@/types/entities';
import { Continent, Continents, Country, Region } from '@/types/locations';
import { ShortcutTypes } from '@/types/shortcut';
import pricingGroupHandler from '@/plugins/pricing-groups-handler';
import countriesPerPremiumHandler from '@/plugins/countries-per-premium-handler';

import { nonReferralPricingProducts } from '@/static/details-of-specific-pricing-products';

const getters: IGetters = {
	detailsOfAllEntities: () => {
		return state.detailsOfAllEntities;
	},
	detailsOfSelectedEntities: () => {
		return state.detailsOfSelectedEntities;
	},
	configurationOfAllEntities: () => {
		return state.postData.studies.concat(state.postData.organisations);
	},
	configurationOfAllStudies: () => {
		return state.postData.studies;
	},
	configurationOfAllOrganisations: () => {
		return state.postData.organisations;
	},
	configurationOfSelectedStudies: () => {
		return state.postData.studies.filter(
			(study) => state.detailsOfSelectedEntities.find(
				(entity) => entity.type === EntityTypes.STUDY && entity.id === study.id
			) !== undefined
		);
	},
	configurationOfSelectedOrganisations: () => {
		return state.postData.organisations.filter(
			(study) => state.detailsOfSelectedEntities.find(
				(entity) => entity.type === EntityTypes.ORGANISATION && entity.id === study.id
			) !== undefined
		);
	},
	configurationOfSelectedEntities: () => {
		const studies = getters.configurationOfSelectedStudies();
		const organisations = getters.configurationOfSelectedOrganisations();
		return studies.concat(organisations);
	},
	doSelectedEntitiesHaveTheSameConfiguration: () => {
		return getters.doSelectedEntitiesHaveTheSameTargeting() && getters.doSelectedEntitiesHaveTheSamePricing();
	},
	doSelectedEntitiesHaveTheSameTargeting: () => {
		const detailsOfSelectedEntities = state.detailsOfSelectedEntities;
		if (!detailsOfSelectedEntities.length) return true;

		return (
			detailsOfSelectedEntities.find(
				(detailsOfEntity) => detailsOfEntity.targetingGroup !== detailsOfSelectedEntities[0].targetingGroup
			) === undefined
		);
	},
	doSelectedEntitiesHaveTheSamePricing: () => {
		const detailsOfSelectedEntities = state.detailsOfSelectedEntities;
		if (!detailsOfSelectedEntities.length) return true;

		return (
			detailsOfSelectedEntities.find(
				(detailsOfEntity) => detailsOfEntity.pricingGroup !== detailsOfSelectedEntities[0].pricingGroup
			) === undefined
		);
	},
	isThereAtLeastOneEntityWithPricing: () => {
		const allEntities = getters.configurationOfAllEntities();
		return allEntities.find((entity) => entity.pricing !== undefined && entity.pricing !== '') !== undefined;
	},
	allPricingProducts: () => {
		return state.pricingProducts;
	},
	referralPricingProducts: () => {
		return state.pricingProducts.filter((product) => !nonReferralPricingProducts.includes(product.product_id));
	},
	referralPricingProductGroups: () => {
		return pricingGroupHandler.constructReferralPricingProductGroups();
	},
	campaignEntityConfigItems: () => {
		return state.campaignEntityConfigItems;
	},
	relevantCampaignEntityConfigItemIds: () => {
		const selectedEntities = getters.detailsOfSelectedEntities();
		const campaignEntityConfigItems = getters.campaignEntityConfigItems();
		const relevantItems = campaignEntityConfigItems.filter(
			(item) => selectedEntities.find(
				(entity) => entity.id === parseInt(item.entity_id) && entity.type === item.type
			) !== undefined
		);
		return relevantItems.map((item) => item.id);
	},
	numSelectedProgrammes: () => {
		if (!state.postData || !state.postData.studies) {
			return 0;
		}

		return state.postData.studies.length;
	},
	numSelectedOrganisations: () => {
		if (!state.postData || !state.postData.organisations) {
			return 0;
		}

		return state.postData.organisations.length;
	},
	selectedPresetIndex: () => {
		return state.selectedPresetIndex;
	},
	campaignStartDate: () => {
		if (!state.campaignStartDate || state.campaignStartDate * 1000 <= new Date().getTime()) {
			return null;
		}

		const date: Date = new Date(state.campaignStartDate * 1000);
		const day: string = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate().toString();
		const month: string = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1).toString();

		return `${day}/${month}/${date.getFullYear()}`;
	},
	isRunningCampaign: () => {
		if (!state.campaignStartDate) {
			return false;
		}

		return state.campaignStartDate * 1000 <= new Date().getTime();
	},
	numCheckedCountries: () => {
		const ids = getters.checkedCountryIds();
		return ids.length;
	},
	premiumStates: () => {
		return state.premiumStates;
	},
	continents: () => {
		return state.continents;
	},
	countries: () => {
		return state.countries;
	},
	selectedContinentId: () => {
		if (!state.continents || !Object.keys(state.continents).length) {
			return null;
		}

		return Object.keys(state.continents).find((continentId) => state.continents[continentId].selected);
	},
	checkedCountryIds: () => {
		if (!state.countries || !Object.keys(state.countries).length) {
			return [];
		}

		return Object.keys(state.countries).filter((countryId) => state.countries[countryId].checked);
	},
	countryPremiumLevels: () => {
		const selectedEntities = getters.configurationOfSelectedEntities();
		if (!selectedEntities.length) {
			return {};
		}

		// Premium levels as defined per country (from postData).
		const targetedCountries = selectedEntities[0].targeted_countries;
		if (!targetedCountries) {
			return {};
		}

		const levels = Object.keys(targetedCountries);
		return levels.reduce((acc, level) => {
			(targetedCountries[level] as number[]).forEach((countryId) => {
				acc[countryId] = {
					level,
					name: state.premiumStates[level].name,
					color: state.premiumStates[level].color
				};
			});

			return acc;
		}, {});
	},
	regionShortcuts: () => {
		const contId = getters.selectedContinentId();
		if (!contId || !state.continents || !Object.prototype.hasOwnProperty.call(state.continents, contId)) {
			return [];
		}

		const { regions }: Continent = state.continents[contId];

		return regions.map((regionId) => {
			const region: Region = state.regions[regionId];

			return {
				type: ShortcutTypes.REGION,
				targetId: regionId,
				text: `Select all countries in ${region.name}`,
				name: region.name,
				checked: true
			};
		});
	},
	additionShortcuts: () => {
		const contId = getters.selectedContinentId();
		if (!contId || !state.continents || !Object.prototype.hasOwnProperty.call(state.continents, contId)) {
			return [];
		}

		const { name }: Continent = state.continents[contId];

		return [
			{
				type: null,
				targetId: null,
				text: 'Select all countries worldwide',
				name: 'worldwide',
				checked: true
			},
			{
				type: ShortcutTypes.CONTINENT,
				targetId: contId,
				text: `Select all countries in ${name}`,
				name,
				checked: true
			},
			...getters.regionShortcuts(),
			{
				type: ShortcutTypes.FILTER,
				targetId: null,
				text: 'Select all filtered countries',
				name: 'filtered',
				checked: true
			}
		];
	},
	removalShortcuts: () => {
		return [
			{
				type: null,
				targetId: null,
				text: 'Clear Selection',
				name: 'clear',
				checked: false
			}
		];
	},
	visibleCountries: () => {
		const continentId = getters.selectedContinentId();
		if (!continentId || !state.countries) {
			return [];
		}

		const visibleCountryIds: string[] = [];
		const searchQueryItems = getters.searchQueryItems();
		for (const countryId in state.countries) {
			const country = state.countries[countryId];
			if (!country.continents.includes(continentId)) {
				continue;
			}

			if (searchQueryItems.length && !searchQueryItems.find((item) => country.name.toLowerCase().includes(item))) {
				continue;
			}

			visibleCountryIds.push(countryId);
		}

		return visibleCountryIds.sort((a: string, b: string) => (state.countries[a].name < state.countries[b].name ? -1 : 1));
	},
	filteredCountries: () => {
		if (!state.countries) {
			return [];
		}

		const visibleCountryIds: string[] = [];
		const searchQueryItems = getters.searchQueryItems();
		for (const countryId in state.countries) {
			const country = state.countries[countryId];

			if (searchQueryItems.length && !searchQueryItems.find((item) => country.name.toLowerCase().includes(item))) {
				continue;
			}

			visibleCountryIds.push(countryId);
		}

		return visibleCountryIds.sort((a: string, b: string) => (state.countries[a].name < state.countries[b].name ? -1 : 1));
	},
	countriesPerPremium: () => {
		const selectedEntities = getters.configurationOfSelectedEntities();
		if (!selectedEntities.length) {
			return {};
		}

		return countriesPerPremiumHandler.convertEntityToCountriesPerPremium(selectedEntities[0]);
	},
	numCountriesPerPremium: () => {
		const selectedEntities = getters.configurationOfSelectedEntities();
		if (!selectedEntities.length) {
			return {};
		}

		return countriesPerPremiumHandler.convertEntityToNumCountriesPerPremium(selectedEntities[0]);
	},
	countriesPerPremiumGroups: () => {
		let groups = countriesPerPremiumHandler.getCountriesPerPremiumGroupsForEntity([], EntityTypes.STUDY);
		groups = countriesPerPremiumHandler.getCountriesPerPremiumGroupsForEntity(groups, EntityTypes.ORGANISATION);

		return groups.map((entity) => ({
			countriesPerPremium: countriesPerPremiumHandler.convertEntityToCountriesPerPremium(entity),
			numCountriesPerPremium: countriesPerPremiumHandler.convertEntityToNumCountriesPerPremium(entity),
			pricing: entity.pricing,
			entityType: entity.type,
			entityId: entity.id
		}));
	},
	postData: () => {
		return state.postData;
	},
	searchQueryCountries: () => {
		return state.searchQueryCountries;
	},
	countriesFilteredBySearchQuery: () => {
		const searchQueryItems = getters.searchQueryItems();
		const countries: Country[] = [];
		for (const country in state.countries) {
			countries.push(state.countries[country]);
		}

		if (!searchQueryItems.length) {
			return countries;
		}

		return countries.filter((country) => searchQueryItems.find(
			(item) => country.name.toLowerCase().includes(item)) !== undefined
		);
	},
	relevantContinentsGivenSearchQuery: () => {
		const relevantContinentIds: string[] = [];
		const searchQueryItems = getters.searchQueryItems();
		const countriesFilteredBySearchQuery = getters.countriesFilteredBySearchQuery();
		for (const continentId in state.continents) {
			const relevantCountries = countriesFilteredBySearchQuery.filter(
				(country) => country.continents.includes(continentId)
			);

			if (searchQueryItems.length && !relevantCountries.length) {
				continue;
			}

			relevantContinentIds.push(continentId);
		}

		const continents: Continents = {};
		relevantContinentIds.forEach((id) => (continents[id] = state.continents[id]));

		return continents;
	},
	searchQueryItems: () => {
		const searchQuery = state.searchQueryCountries.toLowerCase();
		return searchQuery
			.split(',')
			.map((item) => item.trim())
			.filter((item) => item.length);
	},
	campaignId: () => {
		return state.campaignId;
	},
	studyId: () => {
		return state.studyId;
	},
	organisationId: () => {
		return state.organisationId;
	},
	apiUrl: () => {
		return state.apiUrl;
	},
	schedulerUrl: () => {
		return state.schedulerUrl;
	},
	serviceLayerRelayServiceUrl: () => {
		return state.serviceLayerRelayServiceUrl;
	},
	campaignManagementAPIUrl: () => {
		return state.campaignManagementAPIUrl;
	},
	uniAdminUrl: () => {
		return state.uniAdminUrl;
	},
	loading: () => {
		return state.loading;
	},
	router: () => {
		return state.router;
	},
	initialised: () => {
		return state.initialised;
	}
};

export default getters;
