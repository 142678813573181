/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
import { defineComponent, onMounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import PricingSelectionComponent from '@/presentation/pages/pricing-selection/pricing-selection-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new PricingSelectionComponent();

		onMounted(component.mounted.bind(component));

		return {
			pricingProductsMultiSelect: component.pricingProductsMultiSelect,
			referralPricingProductsAsOptionGroups: component.referralPricingProductsAsOptionGroups,
			didSelectedEntitiesHaveTheSamePricingOnToolLoad: component.didSelectedEntitiesHaveTheSamePricingOnToolLoad,
			setPricing: component.setPricing.bind(component)
		};
	}
});
