enum EntityTypes {
	STUDY = 'study',
	ORGANISATION = 'organisation'
}

interface EntityDetails {
	id: number;
	type: EntityTypes;
	title: string;
	degreeType: string;
	degreeName: string;
	targetingGroup: number;
	pricingGroup: number;
}

export { EntityDetails, EntityTypes };
