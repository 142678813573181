/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
import { defineComponent, watch } from 'vue';
import NavigationComponent from '@/presentation/components/navigation/navigation-class';

export default defineComponent({
	setup: () => {
		const component = new NavigationComponent();

		watch(component.currentRouteName, component.updatePreviousRouteName.bind(component));

		return {
			shouldNextBeDisabled: component.shouldNextBeDisabled,
			isEntitySelectionScreen: component.isEntitySelectionScreen,
			isThereAtLeastOneEntityWithPricing: component.isThereAtLeastOneEntityWithPricing,
			navigationOptionBack: component.navigationOptionBack,
			navigationOptionNext: component.navigationOptionNext,
			navigateToExistingPresetBasedSelection: component.navigateToExistingPresetBasedSelection.bind(component),
			shouldNavigationButtonBeShown: component.shouldNavigationButtonBeShown.bind(component),
			triggerNavigationButtonAction: component.triggerNavigationButtonAction.bind(component),
			getNavigationButtonTitle: component.getNavigationButtonTitle.bind(component)
		};
	}
});
