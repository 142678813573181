import { Entity } from '../types/postData';
import { EntityTypes } from '../types/entities';
import { CountryIdAdapter } from '@studyportals/country-id-adapter';
import { AreaId } from '@studyportals/country-id-adapter/src/domain/interfaces/Area.interface';
import { nonReferralPricingProducts } from '@/static/details-of-specific-pricing-products';
import { Pricing, ICampaignAuditorData }
	from '@studyportals/campaign-management-api-interface/src/domain/auditor/campaigns/geotargeting.entities';
import { CampaignAuditorData } from '../types/campaign-auditor-data';
import { IGeotargetingArrayBasedGroup, IGeotargetingNumberBasedGroup } from '@/types/i-auditor';

class CampaignAuditorAdapter {
	private countryIDAdapter = new CountryIdAdapter();

	public translateGeotarginatorDataToCampaignAuditorData(studyEntities: Entity[], organisationEntities: Entity[]): ICampaignAuditorData {
		const entities = this.combineEntities(studyEntities, organisationEntities);

		const geoGroups = this.translateGeotargetingGroups(entities);
		const pricing = this.translatePricing(entities);

		return new CampaignAuditorData(geoGroups, pricing);
	}

	private translateGeotargetingGroups(entities: Entity[]): IGeotargetingNumberBasedGroup[] {
		const geoGroups: IGeotargetingArrayBasedGroup[] = [];

		for (const entity of entities) {
			let foundGeoGroup = geoGroups.find((group) => {
				return (
					group.c.p.toString() === entity.targeted_countries.p.toString() &&
					group.c.lp.toString() === entity.targeted_countries.lp.toString() &&
					group.c.sp.toString() === entity.targeted_countries.sp.toString()
				);
			});

			if (!foundGeoGroup) {
				foundGeoGroup = {
					c: entity.targeted_countries,
					s: 0,
					o: 0
				};

				geoGroups.push(foundGeoGroup);
			}

			if (entity.type === EntityTypes.STUDY) {
				foundGeoGroup.s++;
			} else {
				foundGeoGroup.o++;
			}
		}

		return this.translateCountriesToAmountOfCountries(geoGroups);
	}

	private translateCountriesToAmountOfCountries(geoGroups: IGeotargetingArrayBasedGroup[]): IGeotargetingNumberBasedGroup[] {
		const numberBasedGroups: IGeotargetingNumberBasedGroup[] = [];
		for (const geoGroup of geoGroups) {
			numberBasedGroups.push({
				c: {
					p: geoGroup.c.p.length,
					lp: geoGroup.c.lp.length,
					sp: geoGroup.c.sp.length,
					b:
						this.countryIDAdapter.countriesPerArea[AreaId.WORLD].length -
						geoGroup.c.p.length -
						geoGroup.c.lp.length -
						geoGroup.c.sp.length
				},
				s: geoGroup.s,
				o: geoGroup.o
			});
		}

		return numberBasedGroups;
	}

	private combineEntities(studyEntities: Entity[], organisationEntities: Entity[]): Entity[] {
		studyEntities.forEach((study) => (study.type = EntityTypes.STUDY));
		organisationEntities.forEach((organisation) => (organisation.type = EntityTypes.ORGANISATION));

		const entities = [...studyEntities, ...organisationEntities];

		return entities;
	}

	private translatePricing(entities: Entity[]): Pricing {
		const pricing = new Pricing();

		const productIds = nonReferralPricingProducts.slice();

		for (const entity of entities) {
			const studyFound = pricing.s[entity.pricing] && entity.type === EntityTypes.STUDY;
			if (studyFound) {
				for (const productId of productIds) {
					pricing.s[productId]++;
				}
				pricing.s[entity.pricing]++;

				continue;
			}

			const organisationFound = pricing.o[entity.pricing] && entity.type === EntityTypes.ORGANISATION;
			if (organisationFound) {
				for (const productId of productIds) {
					pricing.o[productId]++;
				}

				pricing.o[entity.pricing]++;

				continue;
			}

			if (!studyFound && !organisationFound) {
				for (const productId of productIds) {
					if (entity.type === EntityTypes.STUDY) {
						pricing.s[productId] = 1;
					} else {
						pricing.o[productId] = 1;
					}
				}

				if (entity.type === EntityTypes.STUDY) {
					pricing.s[entity.pricing] = 1;
				} else {
					pricing.o[entity.pricing] = 1;
				}
			}
		}

		return pricing;
	}
}

export default new CampaignAuditorAdapter();
