import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "SetSelectedCountryLevel" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumLabel = _resolveComponent("PremiumLabel")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.premiumStates, (premium, level) => {
      return (_openBlock(), _createElementBlock("li", {
        key: premium.name,
        onClick: ($event: any) => (_ctx.setCheckedToPremiumLevel(level)),
        class: "SetLevelListItem"
      }, [
        _createVNode(_component_PremiumLabel, {
          premium: premium,
          class: "PremiumButton"
        }, null, 8 /* PROPS */, ["premium"])
      ], 8 /* PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}