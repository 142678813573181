import { computed, ref } from 'vue';
import { EntityDetails, EntityTypes } from '@/types/entities';
import { Entity } from '@/types/postData';
import { PricingProduct } from '@/types/pricing';
import store from '@/store';

export default class OverviewComponent {
	public selectedOverallTab = ref(-1);

	public selectedEntitiesDetails = computed((): string => {
		const amountOfOrganisations = this.numSelectedOrganisations.value;
		const amountOfStudies = this.numSelectedProgrammes.value;
		const organisationsText = `${amountOfOrganisations} ${amountOfOrganisations === 1 ? 'organisation' : 'organisations'}`;
		const studiesText = `${amountOfStudies} ${amountOfStudies === 1 ? 'study' : 'studies'}`;
		return `${organisationsText} and ${studiesText}`;
	});

	public pricingDescription = computed((): string => {
		const product = this.selectedPricingProduct.value;
		if (!product) {
			return '';
		}

		return product.description;
	});

	public pricingDetails = computed((): string => {
		const description = this.pricingDescription.value;
		if (!description) {
			return '';
		}

		const pricing = description.split(' ').pop();
		return pricing ? pricing : '';
	});

	public detailsOfSelectedEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfSelectedEntities();
	});

	public numCountriesPerPremium = computed((): { [key: string]: number } => {
		return store.getters.numCountriesPerPremium();
	});

	public doSelectedEntitiesHaveTheSameConfiguration = computed((): boolean => {
		return store.getters.doSelectedEntitiesHaveTheSameConfiguration();
	});

	public countriesPerPremium = computed((): { [key: string]: number[] } => {
		return store.getters.countriesPerPremium();
	});

	private configurationOfSelectedEntities = computed((): Entity[] => {
		return store.getters.configurationOfSelectedEntities();
	});

	private referralPricingProducts = computed((): PricingProduct[] => {
		return store.getters.referralPricingProducts();
	});

	private numSelectedProgrammes = computed((): number => {
		const selectedProgrammes = store.getters.configurationOfSelectedStudies();
		return selectedProgrammes.length;
	});

	private numSelectedOrganisations = computed((): number => {
		const selectedOrganisations = store.getters.configurationOfSelectedOrganisations();
		return selectedOrganisations.length;
	});

	private selectedPricingProduct = computed((): PricingProduct | undefined => {
		const selectedEntities = this.configurationOfSelectedEntities.value;
		const pricingProducts = this.referralPricingProducts.value;
		if (!selectedEntities.length) {
			return;
		}

		return pricingProducts.find((product) => product.product_id === selectedEntities[0].pricing);
	});

	public retrieveEntityType(detailsOfEntity: EntityDetails): string {
		return this.isEntityTypeStudy(detailsOfEntity) ? 'Programme' : 'Organisation';
	}

	public isEntityTypeStudy(detailsOfEntity: EntityDetails): boolean {
		return detailsOfEntity.type === EntityTypes.STUDY;
	}

	public toggleOverallTab(tabIndex: number): void {
		this.selectedOverallTab.value = this.selectedOverallTab.value === tabIndex ? -1 : tabIndex;
	}
}
