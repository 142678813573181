import { defineComponent, toRef, Ref } from 'vue';
import { Premium } from '@/types/premiums';
import PremiumLabelComponent from '@/presentation/components/premium-label/premium-label-class';

export default defineComponent({
	props: {
		premium: { type: Object, default: {} }
	},
	setup: (props) => {
		const premium = toRef(props, 'premium') as Ref<Premium>;
		const component = new PremiumLabelComponent(premium);

		return {
			premium,
			textColor: component.textColor
		};
	}
});
