import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "StudyListingOverview",
  class: "ListingOverview"
}
const _hoisted_2 = { class: "ListingGeoTargetingMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListingOverview = _resolveComponent("ListingOverview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, " This study is attached to a geotargeted campaign (Campaign id: " + _toDisplayString(_ctx.campaignId) + "). ", 1 /* TEXT */),
    _createVNode(_component_ListingOverview)
  ]))
}