import { PricingProduct, PricingProductGroup } from '@/types/pricing';
import store from '@/store';

class PricingGroupsHandler {
	private listOfAllGroups: PricingProductGroup[] = [];

	public constructReferralPricingProductGroups(): PricingProductGroup[] {
		this.resetListOfPricingGroups();

		const referralPricingProducts = store.getters.referralPricingProducts();
		referralPricingProducts.forEach((product) => {
			product.pricing_groups.forEach((groupLabel) => {
				this.updateListOfAllPricingGroupsInLineWithProductAndPricingGroup(product, groupLabel);
			});
		});

		return this.sortGroups();
	}

	private updateListOfAllPricingGroupsInLineWithProductAndPricingGroup(product: PricingProduct, groupLabel: string): void {
		const indexOfGroup = this.listOfAllGroups.findIndex(
			(group) => group.label === groupLabel
		);
		if (indexOfGroup === -1) {
			this.createNewPricingGroup(groupLabel, product);
			return;
		}

		this.updateExistingPricingGroup(product, indexOfGroup);
	}

	private createNewPricingGroup(label: string, product: PricingProduct): void {
		const createdGroup = {
			label,
			products: [product]
		};
		this.listOfAllGroups.push(createdGroup);
	}

	private updateExistingPricingGroup(product: PricingProduct, indexOfGroup: number): void {
		const group = this.listOfAllGroups[indexOfGroup];
		// If this pricing product does not have the same label as another one in the list, just include it.
		const indexOfProduct = group.products.findIndex(
			(includedProduct) => includedProduct.description === product.description
		);
		if (indexOfProduct === -1) {
			this.includeProductInGroup(product, indexOfGroup);
			return;
		}

		const entities = store.getters.configurationOfAllEntities();
		const relevantEntity = entities.find(
			(entity) => entity.pricing === group.products[indexOfProduct].product_id
		);
		// If the pricing product in the list is already attached to a study / organisation, don't include the checked product.
		if (relevantEntity) {
			return;
		}

		// If not, remove the currently included pricing product, so that the latest one can be added instead.
		group.products.splice(indexOfProduct, 1);
		this.includeProductInGroup(product, indexOfGroup);
	}

	private includeProductInGroup(product: PricingProduct, indexOfGroup: number): void {
		this.listOfAllGroups[indexOfGroup].products.push(product);
	}

	private sortGroups(): PricingProductGroup[] {
		let legacyGroups: PricingProductGroup[] = [];
		const indexOfLegacyGroup = this.listOfAllGroups.findIndex(
			(group) => group.label === 'legacy'
		);
		// If a legacy group can be found, remove it from the list, so that it can be included at the end.
		if (indexOfLegacyGroup !== -1) {
			legacyGroups = this.listOfAllGroups.splice(indexOfLegacyGroup, 1);
		}

		// Make sure the groups are sorted as such: 2025, 2024, 2023, legacy.
		return this.listOfAllGroups.sort().reverse().concat(legacyGroups);
	}

	private resetListOfPricingGroups(): void {
		this.listOfAllGroups = [];
	}
}

export default new PricingGroupsHandler();
