import state from './state';
import store from '@/store';
import getters from './getters';
import mutations from './mutations';

import { IActions } from '@/types/store/i-actions';
import { Entity, PostDataWithIdsAsStrings } from '@/types/postData';
import { EntityTypes } from '@/types/entities';
import { Countries } from '@/types/locations';
import { RemainingRouteNames } from '@/types/i-router';
import { ShortcutTypes } from '@/types/shortcut';
import { LoadingStates } from '@/types/i-loading';

import requests from '@/plugins/requests';
import EntityDetailsHandler from '@/plugins/entity-details-handler';
import SchedulerRequestsHandler from '@/plugins/scheduler-requests-handler';
import PricingHandler from '@/plugins/pricing-handler';
import LoadingHandler from '@/plugins/loading-handler';
import campaignAuditorAdapter from '../plugins/campaign-auditor-adapter';
import AuditorCampaignManagementAPIClient from '@/plugins/auditor-campaign-management-api-client';

const actions: IActions = {
	getCampaignData: async (isListingOverview) => {
		let postData: PostDataWithIdsAsStrings | null = null;
		if (!isListingOverview && SchedulerRequestsHandler.shouldChangesGoThroughScheduler()) {
			postData = await SchedulerRequestsHandler.retrieveTargetingSettingsFromScheduler();
		} else {
			const apiUrl = `${state.apiUrl}/campaign/${state.campaignId}`;
			const response = await fetch(apiUrl);
			if (!response.ok) {
				throw new Error('Something went wrong while retrieving country targeting data from the Geo back-end.');
			}

			postData = await response.json();
		}

		mutations.setCampaignData(postData);

		await actions.retrieveAllPricingProducts();
		actions.getEntityDetails();
		mutations.setLoadingState(LoadingStates.INACTIVE);
	},
	getEntityDetails: () => {
		if (!state.postData) {
			return;
		}

		const entityDetails = EntityDetailsHandler.prepareDetailsForAllEntities(state.postData);
		mutations.setDetailsOfAllEntities(entityDetails);
	},
	getEntityData: async (type) => {
		const apiUrl = `${state.apiUrl}/${type}?${type}_ids=${state[`${type}Id`]}`;

		const response = await fetch(apiUrl);
		if (!response.ok) {
			throw new Error('Something went wrong while retrieving entity data from the geo back-end.');
		}

		const result = await response.json();
		if (!result.length) {
			return;
		}

		state.campaignId = result[0].campaign_id;

		await actions.getCampaignData(true);
	},
	setCampaignData: async () => {
		try {
			LoadingHandler.beginDisplayingSavingProgress();
			await PricingHandler.savePricingProducts();

			const postData = state.postData;

			const studyEntities = JSON.parse(JSON.stringify(store.getters.configurationOfSelectedStudies())) as Entity[];
			const organisationEntities = JSON.parse(JSON.stringify(store.getters.configurationOfSelectedOrganisations())) as Entity[];

			// Cleanup to prepare postData to Geotarginator, Geotarginator would store this
			postData.studies.forEach((entity) => delete entity.pricing);
			postData.organisations.forEach((entity) => delete entity.pricing);

			if (SchedulerRequestsHandler.shouldChangesGoThroughScheduler()) {
				await SchedulerRequestsHandler.updateTargetingSettingsInScheduler(postData);
			} else {
				await requests.signedPostRequest(state.cognito, `${state.apiUrl}/campaign`, postData);

				const auditorData = campaignAuditorAdapter.translateGeotarginatorDataToCampaignAuditorData(
					studyEntities,
					organisationEntities
				);

				await AuditorCampaignManagementAPIClient.storeCampaignDataInAuditor(true, auditorData);
			}
			// eslint-disable-next-line no-useless-catch
		} catch (e) {
			throw e;
		} finally {
			LoadingHandler.wrapUpSavingProgressDisplayProcess();
		}
	},
	retrieveAllPricingProducts: async () => {
		// eslint-disable-next-line no-useless-catch
		try {
			const products = await PricingHandler.retrieveAllPricingProducts();
			const rawAttachedPricingProductsData = await PricingHandler.retrieveRawAttachedPricingProductsData();
			const campaignStudyConfigItems = PricingHandler.attachPricingProductsForEntities(
				rawAttachedPricingProductsData,
				state.postData.studies,
				EntityTypes.STUDY
			);
			const campaignOrganisationConfigItems = PricingHandler.attachPricingProductsForEntities(
				rawAttachedPricingProductsData,
				state.postData.organisations,
				EntityTypes.ORGANISATION
			);

			const campaignEntityConfigItems = campaignStudyConfigItems.concat(campaignOrganisationConfigItems);
			mutations.setCampaignEntityConfigItems(campaignEntityConfigItems);
			mutations.updateCampaignData(state.postData);
			mutations.setPricingProducts(products);
		} catch (e) {
			throw e;
		}
	},
	setItemsChecked: (data) => {
		const { type, checked, targetId } = data;
		const countryList: Countries = state.countries;
		let countryIds: string[] = Object.keys(countryList);

		switch (type) {
			case ShortcutTypes.REGION:
				countryIds = countryIds.filter((x) => countryList[x].regions.includes(targetId));
				break;
			case ShortcutTypes.CONTINENT:
				countryIds = countryIds.filter((x) => countryList[x].continents.includes(targetId));
				break;
			case ShortcutTypes.FILTER:
				countryIds = getters.filteredCountries();
				break;
		}

		countryIds.forEach((id: string) => {
			mutations.setCountryChecked({ checked, id });
		});
	},
	setCheckedToPremiumLevel: (level) => {
		const ids = getters.checkedCountryIds();
		ids.forEach((id) => {
			mutations.setCountryPremium({ id, level });
			mutations.setCountryChecked({ checked: false, id });
		});
	},
	resetToDefault: () => {
		mutations.setSelectedContinent(1);
		mutations.setCurrentRoute(RemainingRouteNames.DEFAULT);
		const ids = getters.checkedCountryIds();
		ids.forEach((id) =>
			mutations.setCountryChecked({
				id,
				checked: false
			})
		);
	}
};

export default actions;
