import { computed, ref, Ref } from 'vue';
import { PricingProduct } from '@/types/pricing';
import { Entity } from '@/types/postData';
import { EntityDetails, EntityTypes } from '@/types/entities';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import store from '@/store';

export default class EntitySelectionFiltersComponent {
	public entitySelectionPricingFilter: Ref<IMultiSelect | null> = ref(null);
	public entitySelectionDegreeFilter: Ref<IMultiSelect | null> = ref(null);

	constructor(private emit: (name: string, value: string) => void) {}

	public pricingFilterOptions = computed((): IOption[] => {
		const relevantProducts = this.referralPricingProducts.value.filter(
			(product) => {
				return this.allEntities.value.find(
					(entity) => entity.pricing === product.product_id
				) !== undefined;
			}
		);
		const options = relevantProducts.map((product) => ({
			label: product.description,
			value: product.product_id
		}));

		if (this.allEntities.value.find((entity) => !entity.pricing) !== undefined) {
			options.unshift({
				label: 'No pricing product',
				value: '0'
			});
		}

		options.unshift({
			label: 'Any pricing product',
			value: ''
		});

		return options;
	});

	public degreeFilterOptions = computed((): IOption[] => {
		const allDegreeTypes = this.detailsOfAllEntities.value.map((entity) =>
			entity.type === EntityTypes.ORGANISATION ? 'Organisation' : entity.degreeType
		);
		const uniqueDegreeTypes = allDegreeTypes.filter(
			(item, position) => allDegreeTypes.indexOf(item) === position
		);
		const sortedDegreeTypes = uniqueDegreeTypes.sort((a, b) => (a < b ? -1 : 1));
		const options = sortedDegreeTypes.map((type) => ({
			label: type,
			value: type
		}));

		options.unshift({
			label: 'Any degree type',
			value: ''
		});

		return options;
	});

	private referralPricingProducts = computed((): PricingProduct[] => {
		return store.getters.referralPricingProducts();
	});

	private allEntities = computed((): Entity[] => {
		return store.getters.configurationOfAllEntities();
	});

	private detailsOfAllEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfAllEntities();
	});

	public mounted(): void {
		this.prefillPricingFilter();
		this.prefillDegreeFilter();
	}

	public selectPricingFilterOption(pricing): void {
		this.emit('pricingFilterSelectionChanged', pricing.value as string);
	}

	public selectDegreeFilterOption(degreeType): void {
		this.emit('degreeFilterSelectionChanged', degreeType.value as string);
	}

	private prefillPricingFilter(): void {
		if (!this.entitySelectionPricingFilter.value) {
			return;
		}

		// Select the 'Any pricing product' option by default.
		this.entitySelectionPricingFilter.value.selectedOption = this.pricingFilterOptions.value.find(
			(option) => option.value === ''
		);
	}

	private prefillDegreeFilter(): void {
		if (!this.entitySelectionDegreeFilter.value) {
			return;
		}

		// Select the 'Any degree type' option by default.
		this.entitySelectionDegreeFilter.value.selectedOption = this.degreeFilterOptions.value.find(
			(option) => option.value === ''
		);
	}
}
