import { ICampaignAuditorData, NumberMap, Pricing }
	from '@studyportals/campaign-management-api-interface/src/domain/auditor/campaigns/geotargeting.entities';
import { IGeotargetingNumberBasedGroup } from '@/types/i-auditor';

export class CampaignAuditorData implements ICampaignAuditorData {
	private _geo_remainders: NumberMap;

	public constructor(public geo: IGeotargetingNumberBasedGroup[], public pricing: Pricing) {
		this._geo_remainders = { s: 0, o: 0 };

		this.buildRemainders();
	}

	public get geo_remainders(): NumberMap {
		return this._geo_remainders;
	}

	private addGeoRemainderStudies(value: number): void {
		this._geo_remainders.s += value;
	}

	private addGeoRemainderOrganisations(value: number): void {
		this._geo_remainders.o += value;
	}

	private buildRemainders(): void {
		if (this.geo.length <= 5) {
			return;
		}

		const geoGroupRemainders = this.geo.splice(5);

		for (const remainderGroup of geoGroupRemainders) {
			this.addGeoRemainderStudies(remainderGroup.s);
			this.addGeoRemainderOrganisations(remainderGroup.o);
		}
	}
}
