import { computed, Ref } from 'vue';
import { Premium } from '@/types/premiums';

export default class PremiumLabelComponent {
	constructor(public premium: Ref<Premium>) {}

	public textColor = computed((): string => {
		return this.premium.value.name && this.premium.value.name !== 'Basic' ? '#ffffff' : '#313233';
	});
}
