/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineComponent } from 'vue';
import ContinentIndicatorComponent from '@/presentation/components/continent-indicator/continent-indicator-class';

export default defineComponent({
	setup: () => {
		const component = new ContinentIndicatorComponent();

		return {
			continents: component.continents,
			setSelectedContinent: component.setSelectedContinent.bind(component)
		};
	}
});
