import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "EntitySelectionFilters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelect, {
      ref: "entitySelectionPricingFilter",
      class: "EntitySelectionFilter",
      label: "Filter by pricing",
      placeholder: "Select a pricing product",
      options: _ctx.pricingFilterOptions,
      onUpdated: _ctx.selectPricingFilterOption
    }, null, 8 /* PROPS */, ["options", "onUpdated"]),
    _createVNode(_component_MultiSelect, {
      ref: "entitySelectionDegreeFilter",
      class: "EntitySelectionFilter",
      label: "Filter by degree type",
      placeholder: "Select a degree type",
      options: _ctx.degreeFilterOptions,
      onUpdated: _ctx.selectDegreeFilterOption
    }, null, 8 /* PROPS */, ["options", "onUpdated"])
  ]))
}