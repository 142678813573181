import { Component, defineComponent } from 'vue';
import CountryListComponent from '@/presentation/components/country-list/country-list-class';
import Country from '@/presentation/components/country/country.vue';

export default defineComponent({
	components: {
		Country: Country as Component
	},
	setup: () => {
		const component = new CountryListComponent();

		return {
			visibleCountries: component.visibleCountries
		};
	}
});
