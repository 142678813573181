/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent, toRef } from 'vue';
import CountriesPerLevel from '@/presentation/components/countries-per-level/countries-per-level.vue';
import CountryTargetingSummaryComponent from '@/presentation/components/country-targeting-summary/country-targeting-summary-class';

export default defineComponent({
	components: {
		CountriesPerLevel: CountriesPerLevel as Component
	},
	props: {
		id: { type: Number, default: 0 },
		tabSelected: { type: Boolean, default: false },
		numCountriesPerPremium: { type: Object, default: {} },
		countriesPerPremium: { type: Object, default: {} },
		pricing: { type: [String, null] }
	},
	setup: (props, context) => {
		const component = new CountryTargetingSummaryComponent(
			context.emit,
			props.id,
			toRef(props, 'tabSelected'),
			toRef(props, 'numCountriesPerPremium'),
			toRef(props, 'countriesPerPremium'),
			toRef(props, 'pricing')
		);

		return {
			selectedTargetingTab: component.selectedTargetingTab,
			tabSelected: component.tabSelected,
			pricingValue: component.pricingValue,
			premiumStates: component.premiumStates,
			shouldPricingBeShown: component.shouldPricingBeShown,
			countryTargetingDetails: component.countryTargetingDetails,
			numCountriesPerPremium: component.numCountriesPerPremium,
			countriesOfSelectedTargetingTab: component.countriesOfSelectedTargetingTab,
			toggleOverallTab: component.toggleOverallTab.bind(component),
			setTargetingTab: component.setTargetingTab.bind(component)
		};
	}
});
