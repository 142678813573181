import { Component, defineComponent } from 'vue';
import ListingOverviewComponent from '@/presentation/components/listing-overview/listing-overview-class';
import OverviewList from '@/presentation/components/overview-list/overview-list.vue';

export default defineComponent({
	components: {
		OverviewList: OverviewList as Component
	},
	setup: () => {
		const component = new ListingOverviewComponent();

		return {
			campaignEditorUrl: component.campaignEditorUrl
		};
	}
});
