import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "ShortcutsBlock" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortcuts, (shortcut) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "Shortcut",
        key: shortcut.name + 'shortcut',
        onClick: ($event: any) => (_ctx.setItemsChecked(shortcut))
      }, _toDisplayString(shortcut.text), 9 /* TEXT, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}