import {
	CampaignEntityConfigItem, CampaignEntityConfigItemServiceLayer, RawPricingProductsCollection,
	ServiceLayerRawAttachedPricingProductsData, RawPricingProduct
} from '@/types/pricing';
import { EntityTypes } from '@/types/entities';
import { CampaignEntityConfigsResponse, RawPricingProductsResponse } from '@/types/i-request-results';
import { nonReferralPricingProducts } from '@/static/details-of-specific-pricing-products';
import CookieHandler from '@/plugins/cookie-handler';
import store from '@/store';

const maxBatchSize = 50;

class ServiceLayerRequestsHandler {
	private get baseUrl(): string {
		return store.getters.serviceLayerRelayServiceUrl();
	}

	private get campaignId(): string {
		const id = store.getters.campaignId();
		return id.toString();
	}

	public async removeExistingPricingProducts(): Promise<void> {
		const relevantIds = store.getters.relevantCampaignEntityConfigItemIds();
		let body, options;

		for (let i = 0; i < relevantIds.length; i += maxBatchSize) {
			body = {
				campaignEntityConfigIDs: relevantIds.slice(i, i + maxBatchSize)
			};
			options = {
				method: 'DELETE',
				body: JSON.stringify(body),
				headers: {
					Authorization: CookieHandler.retrieveTokenIdCookie()
				}
			};

			const response = await fetch(
				`${this.baseUrl}/campaigns/remove-entity-configs-by-ids`,
				options as RequestInit
			);
			if (!response.ok) {
				throw new Error('Something went wrong while removing pricing data using the SL Relay Service.');
			}
		}
	}

	public async savePricingProducts(): Promise<CampaignEntityConfigItem[]> {
		const tokenId = CookieHandler.retrieveTokenIdCookie();
		let entityConfigs = [];

		entityConfigs = entityConfigs.concat(this.constructPricingProductsForEntity(EntityTypes.STUDY));
		entityConfigs = entityConfigs.concat(this.constructPricingProductsForEntity(EntityTypes.ORGANISATION));

		let body, options;
		let items: CampaignEntityConfigItem[] = [];

		for (let i = 0; i < entityConfigs.length; i += maxBatchSize) {
			body = {
				entityConfigs: entityConfigs.slice(i, i + maxBatchSize)
			};
			options = {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					Authorization: tokenId
				}
			};

			const response = await fetch(
				`${this.baseUrl}/campaigns/add-entity-configs`,
				options as RequestInit
			);
			if (!response.ok) {
				throw new Error('Something went wrong while saving pricing data using the SL Relay Service.');
			}

			const result = (await response.json()) as CampaignEntityConfigsResponse;
			items = items.concat(this.convertEntityConfigsAddResponseToItems(result.campaignEntityConfigs));
		}

		return items;
	}

	public async retrieveRawAttachedPricingProductsData(): Promise<ServiceLayerRawAttachedPricingProductsData> {
		const body = { campaignID: this.campaignId };
		const options = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(
			`${this.baseUrl}/campaigns/retrieve-entity-config-by-campaign-id`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while retrieving pricing data using the SL Relay Service.');
		}

		return (await response.json()) as ServiceLayerRawAttachedPricingProductsData;
	}

	public async retrieveRawPricingProductsData(): Promise<RawPricingProductsCollection> {
		const options = {
			method: 'GET',
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(`${this.baseUrl}/products/retrieve-all`, options);
		if (!response.ok) {
			throw new Error('Something went wrong while retrieving pricing data using the SL Relay Service.');
		}

		const result = (await response.json()) as RawPricingProductsResponse;
		return result.products;
	}

	public async activateTableauReportingForCampaign(): Promise<string> {
		const body = { campaignID: this.campaignId };
		const options = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: CookieHandler.retrieveTokenIdCookie()
			}
		};

		const response = await fetch(
			`${this.baseUrl}/campaigns/activate-tableau-reporting`,
			options
		);
		if (!response.ok) {
			throw new Error('Something went wrong while activating tableau reporting using the SL Relay Service.');
		}

		const result = (await response.json()) as { campaignID: string };
		return result.campaignID;
	}

	private convertEntityConfigsAddResponseToItems(input: RawPricingProduct[]): CampaignEntityConfigItem[] {
		return input.map((outerItem) => {
			const innerItem = outerItem[Object.keys(outerItem)[0]];
			return {
				id: innerItem.id,
				entity_id: innerItem.sp_id,
				type: innerItem.type
			};
		});
	}

	private constructPricingProductsForEntity(type: EntityTypes): CampaignEntityConfigItemServiceLayer[] {
		const detailsOfEntities = store.getters.detailsOfSelectedEntities();
		const configurationOfSelectedEntities =
			type === EntityTypes.STUDY
				? store.getters.configurationOfSelectedStudies()
				: store.getters.configurationOfSelectedOrganisations();
		if (configurationOfSelectedEntities.length <= 0) {
			return [];
		}

		const entityConfigs: CampaignEntityConfigItemServiceLayer[] = [];
		const productIds = nonReferralPricingProducts.slice();
		productIds.push(configurationOfSelectedEntities[0].pricing);

		for (const configuration of configurationOfSelectedEntities) {
			const details = detailsOfEntities.find(
				(entity) => entity.id === configuration.id && entity.type === type
			);
			if (!details) {
				continue;
			}

			for (const productId of productIds) {
				entityConfigs.push({
					entityID: configuration.id.toString(),
					campaignID: this.campaignId,
					productID: productId,
					entityType: details.type
				});
			}
		}

		return entityConfigs;
	}
}

export default new ServiceLayerRequestsHandler();
