import { defineComponent } from 'vue';
import SelectedCountryCounterComponent from '@/presentation/components/selected-country-counter/selected-country-counter-class';

export default defineComponent({
	setup: () => {
		const component = new SelectedCountryCounterComponent();

		return {
			numCheckedCountries: component.numCheckedCountries
		};
	}
});
