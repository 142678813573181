import { Component, defineComponent } from 'vue';
import Final from '@/presentation/pages/final/final/final.vue';
import Close from '@/presentation/components/close/close.vue';
import SuccessComponent from '@/presentation/pages/final/success/success-class';

export default defineComponent({
	components: {
		Final: Final as Component,
		Close: Close as Component
	},
	setup: () => {
		const component = new SuccessComponent();

		return {
			campaignStartDate: component.campaignStartDate
		};
	}
});
