import store from '@/store';
import RollbarOperator from '@/plugins/rollbar-operator';
import { computed, watch } from 'vue';
import { MainFlowRouteNames, RemainingRouteNames, TRouteNames } from '@/types/i-router';

class OpenCloseAppHandler {
	public lastRouteName = '';

	private currentRouteName = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});

	constructor() {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		watch(this.currentRouteName, this.updatePreviousRouteName.bind(this));
	}

	public async openApp(): Promise<void> {
		store.actions.resetToDefault();

		try {
			await store.actions.getCampaignData(false);
			store.mutations.setCurrentRoute(MainFlowRouteNames.ENTITY_SELECTION);
		} catch (e) {
			RollbarOperator.triggerError(e as Error);
			store.mutations.setCurrentRoute(RemainingRouteNames.FINAL_ERROR);
			throw e;
		}
	}

	public closeApp(isOverlay = false): void {
		const router = store.getters.router();
		const nonSuccessFinalRoutes: string[] = [RemainingRouteNames.FINAL_ERROR, RemainingRouteNames.FINAL_EXIT];
		const successFinalRoutes: string[] = [RemainingRouteNames.FINAL_SUCCESS];
		const finalRoutes = successFinalRoutes.concat(nonSuccessFinalRoutes);

		if (isOverlay && nonSuccessFinalRoutes.includes(router.current)) {
			return;
		}

		if (finalRoutes.includes(router.current)) {
			this.decideDestroy();
			store.mutations.setCurrentRoute(RemainingRouteNames.DEFAULT);
		} else {
			store.mutations.setCurrentRoute(RemainingRouteNames.FINAL_EXIT);
		}
	}

	public abortClose(): void {
		store.mutations.setCurrentRoute(this.lastRouteName as TRouteNames);
	}

	private decideDestroy(): void {
		document.dispatchEvent(new CustomEvent('destroy_geo_targeting'));
	}

	public updatePreviousRouteName(to: string, from: string): void {
		this.lastRouteName = from;
	}
}

export default new OpenCloseAppHandler();
