/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent } from 'vue';
import SetSelectedCountryLevelComponent from '@/presentation/components/set-selected-country-level/set-selected-country-level-class';
import PremiumLabel from '@/presentation/components/premium-label/premium-label.vue';

export default defineComponent({
	components: {
		PremiumLabel: PremiumLabel as Component
	},
	setup: () => {
		const component = new SetSelectedCountryLevelComponent();

		return {
			premiumStates: component.premiumStates,
			setCheckedToPremiumLevel: component.setCheckedToPremiumLevel.bind(component)
		};
	}
});
