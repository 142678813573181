import { computed } from 'vue';
import { Entity, TargetedCountries } from '@/types/postData';
import { CountryCountsOfPremiums, CountryCountsOfPremiumsPossiblyUndefined } from '@/types/premiums';
import { EntityTypes } from '@/types/entities';
import { Countries } from '@/types/locations';
import store from '@/store';

class CountriesPerPremiumHandler {
	private countries = computed((): Countries => {
		return store.getters.countries();
	});

	private configOfAllStudies = computed((): Entity[] => {
		return store.getters.configurationOfAllStudies();
	});

	private configOfAllOrganisations = computed((): Entity[] => {
		return store.getters.configurationOfAllOrganisations();
	});

	public convertEntityToCountriesPerPremium(entity: Entity): { [key: string]: number[] } {
		const countries = this.countries.value;
		const targetedCountries: TargetedCountries = entity.targeted_countries;
		let targetedIds: number[] = [];

		const ret = Object.keys(targetedCountries).reduce((acc: object, level: string) => {
			const countryIds: number[] = targetedCountries[level];
			targetedIds = targetedIds.concat(countryIds);
			acc[level] = countryIds.map((x) => countries[x]).sort((a, b) => (a.name < b.name ? -1 : 1));
			return acc;
		}, {});

		ret['b'] = Object.keys(countries)
			.filter((x) => !targetedIds.includes(parseInt(x, 10)))
			.map((x) => countries[x])
			.sort((a, b) => (a.name < b.name ? -1 : 1));

		return ret;
	}

	public convertEntityToNumCountriesPerPremium(entity: Entity): CountryCountsOfPremiums {
		const countries = this.countries.value;
		const countriesPerPremium = this.convertEntityToCountriesPerPremium(entity);
		if (!countriesPerPremium) {
			return { b: 0, lp: 0, p: 0, sp: 0 };
		}

		const ret: CountryCountsOfPremiumsPossiblyUndefined = Object.keys(countriesPerPremium).reduce(
			(acc: CountryCountsOfPremiumsPossiblyUndefined, level: string) => {
				acc[level] = countriesPerPremium[level].length;
				return acc;
			},
			{}
		);

		if (typeof ret['b'] !== 'number') {
			const numOtherStudies: number = Object.values(ret).reduce((acc, x) => (acc + x) as number, 0);
			ret['b'] = Object.keys(countries).length - numOtherStudies;
		}

		return ret as CountryCountsOfPremiums;
	}

	public getCountriesPerPremiumGroupsForEntity(existingGroups: Entity[], entityType: EntityTypes): Entity[] {
		const entities = entityType === EntityTypes.STUDY ? this.configOfAllStudies.value : this.configOfAllOrganisations.value;
		entities.forEach((entity) => {
			if (!entity.pricing) {
				return;
			}

			const stringifiedEntityTargeting = JSON.stringify(entity.targeted_countries);
			const existingGroup = existingGroups.find((group) => {
				return stringifiedEntityTargeting === JSON.stringify(group.targeted_countries) && entity.pricing === group.pricing;
			});
			if (existingGroup !== undefined) {
				return;
			}

			existingGroups.push({
				...entity,
				type: entityType
			});
		});

		return existingGroups;
	}
}

export default new CountriesPerPremiumHandler();
