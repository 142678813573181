import { computed } from 'vue';
import { RemainingRouteNames } from '@/types/i-router';
import { Entity, PostData } from '@/types/postData';
import { CountriesPerPremiumGroup } from '@/types/premiums';
import RollbarOperator from '@/plugins/rollbar-operator';
import store from '@/store';

let saveInProgress = false;

class SetCampaignDataHandler {
	private selectedPresetIndex = computed((): number => {
		return store.getters.selectedPresetIndex();
	});

	private countriesPerPremiumGroups = computed((): CountriesPerPremiumGroup[] => {
		return store.getters.countriesPerPremiumGroups();
	});

	private postData = computed((): PostData => {
		return store.getters.postData();
	});

	private configurationOfAllEntities = computed((): Entity[] => {
		return store.getters.configurationOfAllEntities();
	});

	private configurationOfSelectedStudies = computed((): Entity[] => {
		return store.getters.configurationOfSelectedStudies();
	});

	private configurationOfSelectedOrganisations = computed((): Entity[] => {
		return store.getters.configurationOfSelectedOrganisations();
	});

	public async setCampaignData(): Promise<void> {
		/* Bail out if saving was already triggered earlier. Saving campaign data can be a slow process,
		and so the user might be tempted to press the button multiple times, but it should not trigger multiple saves.*/
		if (saveInProgress) {
			return;
		}

		saveInProgress = true;
		try {
			await store.actions.setCampaignData();
			store.mutations.setCurrentRoute(RemainingRouteNames.FINAL_SUCCESS);
			saveInProgress = false;
		} catch (e) {
			RollbarOperator.triggerError(e as Error);
			store.mutations.setCurrentRoute(RemainingRouteNames.FINAL_ERROR, { savingError: true });
			saveInProgress = false;
		}
	}

	public async setCampaignDataBasedOnPreset(): Promise<void> {
		const postData = this.postData.value;
		const presetIndex = this.selectedPresetIndex.value;
		const groups = this.countriesPerPremiumGroups.value;
		if (!groups[presetIndex]) {
			return;
		}

		postData.studies = this.updateSelectedEntitiesBasedOnPreset(groups[presetIndex], true);
		postData.organisations = this.updateSelectedEntitiesBasedOnPreset(groups[presetIndex], false);

		store.mutations.updateCampaignData(postData);
		await this.setCampaignData();
	}

	private updateSelectedEntitiesBasedOnPreset(relevantGroup: CountriesPerPremiumGroup, isStudies: boolean): Entity[] {
		const configurationOfAllEntities = this.configurationOfAllEntities.value;
		const entityWithCorrectSettings = configurationOfAllEntities.find((item) => item.id === relevantGroup.entityId);
		if (!entityWithCorrectSettings) {
			return;
		}

		const configurationOfAllEntitiesOfType = isStudies ? this.postData.value.studies : this.postData.value.organisations;
		const selectedEntities = isStudies ? this.configurationOfSelectedStudies.value : this.configurationOfSelectedOrganisations.value;
		selectedEntities.forEach((entity) => {
			const entityToUpdate = configurationOfAllEntitiesOfType.find((item) => item.id === entity.id);
			if (!entityToUpdate) {
				return;
			}

			entityToUpdate.targeted_countries = entityWithCorrectSettings.targeted_countries;
			entityToUpdate.pricing = entityWithCorrectSettings.pricing;
		});

		return configurationOfAllEntitiesOfType;
	}
}

export default new SetCampaignDataHandler();
