/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defineComponent, toRef, Ref } from 'vue';
import ShortcutsComponent from '@/presentation/components/shortcuts/shortcuts-class';
import { Shortcut } from '@/types/shortcut';

export default defineComponent({
	props: {
		shortcuts: { type: Array, default: [] }
	},
	setup: (props) => {
		const shortcuts = toRef(props, 'shortcuts') as Ref<Shortcut[]>;
		const component = new ShortcutsComponent();

		return {
			shortcuts,
			setItemsChecked: component.setItemsChecked.bind(component)
		};
	}
});
