import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "CountryManagement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountrySelection = _resolveComponent("CountrySelection")!
  const _component_CountryStateControl = _resolveComponent("CountryStateControl")!
  const _component_Shortcuts = _resolveComponent("Shortcuts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CountrySelection),
    _createVNode(_component_CountryStateControl),
    _createVNode(_component_Shortcuts, {
      shortcuts: _ctx.removalShortcuts,
      class: "Clear"
    }, null, 8 /* PROPS */, ["shortcuts"])
  ]))
}