/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, defineComponent, onBeforeMount } from 'vue';
import AppComponent from '@/presentation/app-class';
import RouterView from '@/presentation/components/router-view/router-view.vue';
import Header from '@/presentation/components/header/header.vue';
import Navigation from '@/presentation/components/navigation/navigation.vue';
import LoadingAnimation from '@/presentation/components/loading-animation/loading-animation.vue';

export default defineComponent({
	components: {
		Header: Header as Component,
		Navigation: Navigation as Component,
		RouterView: RouterView as Component,
		LoadingAnimation: LoadingAnimation as Component
	},
	setup: () => {
		const component = new AppComponent();

		onBeforeMount(component.beforeMount.bind(component));

		return {
			layoutElementStyling: component.layoutElementStyling,
			loadingInProgress: component.loadingInProgress,
			routeIsListing: component.routeIsListing
		};
	}
});
