enum LoadingStates {
	INACTIVE = 'inactive',
	STARTING = 'starting',
	SAVING = 'saving'
}

interface ILoading {
	state: LoadingStates;
	estimatedTimeNeededForSaving: number;
}

interface ILoadingMilestone {
	timePercentage: number;
	progress: number;
}

interface IRelevantLoadingMilestones {
	previous: ILoadingMilestone;
	next: ILoadingMilestone;
}

interface INetworkInfo {
	connection?: {
		downlink?: number;
	};
}

export { LoadingStates, ILoading, ILoadingMilestone, IRelevantLoadingMilestones, INetworkInfo };
