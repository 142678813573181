import { computed, ref, Ref } from 'vue';
import { PricingProductGroup } from '@/types/pricing';
import { Entity } from '@/types/postData';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption, IOptionGroup } from '@studyportals/multiselect/src/interfaces/options.interface';
import store from '@/store';

export default class PricingSelectionComponent {
	public pricingProductsMultiSelect: Ref<IMultiSelect | null> = ref(null);

	public didSelectedEntitiesHaveTheSamePricingOnToolLoad = computed((): boolean => {
		return store.getters.doSelectedEntitiesHaveTheSamePricing();
	});

	public referralPricingProductsAsOptionGroups = computed((): IOptionGroup[] => {
		// Make sure that the pricing products are grouped and sorted logically by price: not 0, 100, 1000, 200, but 0, 100, 200, 1000.
		return this.referralPricingProductGroups.value.map((group) => ({
			label: group.label,
			options: group.products
				.map((product) => ({
					label: product.description,
					value: product.product_id
				}))
				.sort((a, b) => {
					const priceA = parseInt(a.label.split(' ')[1]);
					const priceB = parseInt(b.label.split(' ')[1]);
					return priceA > priceB ? 1 : -1;
				})
		}));
	});

	private referralPricingProductOptions = computed((): IOption[] => {
		const options: IOption[] = [];
		this.referralPricingProductsAsOptionGroups.value.forEach((group) => {
			group.options.forEach((option) => options.push(option));
		});
		return options;
	});

	private referralPricingProductGroups = computed((): PricingProductGroup[] => {
		return store.getters.referralPricingProductGroups();
	});

	private configurationOfSelectedEntities = computed((): Entity[] => {
		return store.getters.configurationOfSelectedEntities();
	});

	private doSelectedEntitiesHaveTheSamePricingSelected = computed((): boolean => {
		const entities = this.configurationOfSelectedEntities.value;

		return entities.find((entity) => entity.pricing !== entities[0].pricing) === undefined;
	});

	public mounted(): void {
		this.prefillPricingDropDown();
	}

	public setPricing(pricing: IOption): void {
		store.mutations.setPricingForSelectedEntities(pricing.value as string);
	}

	private prefillPricingDropDown(): void {
		if (!this.doSelectedEntitiesHaveTheSamePricingSelected.value) {
			return;
		}

		const selectedOption = this.referralPricingProductOptions.value.find(
			(option) => option.value === this.configurationOfSelectedEntities.value[0].pricing
		);
		if (!selectedOption) {
			return;
		}

		this.pricingProductsMultiSelect.value.selectedOption = selectedOption;
	}
}
