/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent } from 'vue';
import OverviewComponent from '@/presentation/pages/overview/overview-class';
import CountryTargetingSummary from '@/presentation/components/country-targeting-summary/country-targeting-summary.vue';

export default defineComponent({
	components: {
		CountryTargetingSummary: CountryTargetingSummary as Component
	},
	setup: () => {
		const component = new OverviewComponent();

		return {
			selectedOverallTab: component.selectedOverallTab,
			selectedEntitiesDetails: component.selectedEntitiesDetails,
			detailsOfSelectedEntities: component.detailsOfSelectedEntities,
			numCountriesPerPremium: component.numCountriesPerPremium,
			countriesPerPremium: component.countriesPerPremium,
			pricingDetails: component.pricingDetails,
			pricingDescription: component.pricingDescription,
			doSelectedEntitiesHaveTheSameConfiguration: component.doSelectedEntitiesHaveTheSameConfiguration,
			isEntityTypeStudy: component.isEntityTypeStudy.bind(component),
			retrieveEntityType: component.retrieveEntityType.bind(component),
			toggleOverallTab: component.toggleOverallTab.bind(component)
		};
	}
});
