import { readonly } from 'vue';
import { IState, IStore } from '@/types/store/i-store';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const store: IStore = { state: readonly(state) as IState, mutations, getters, actions };

export default store;
