import { computed, ref } from 'vue';
import { Entity, PostData } from '@/types/postData';
import { EntityDetails, EntityTypes } from '@/types/entities';
import store from '@/store';

export default class EntitySelectionComponent {
	private pricingFilter = ref('');
	private degreeFilter = ref('');

	public postData = computed((): PostData => {
		return store.getters.postData();
	});

	public detailsOfSelectedEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfSelectedEntities();
	});

	public detailsOfAllEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfAllEntities();
	});

	public configurationOfAllStudies = computed((): Entity[] => {
		return store.getters.configurationOfAllStudies();
	});

	public configurationOfAllOrganisations = computed((): Entity[] => {
		return store.getters.configurationOfAllOrganisations();
	});

	public doSelectedEntitiesHaveTheSameConfiguration = computed((): boolean => {
		return store.getters.doSelectedEntitiesHaveTheSameConfiguration();
	});

	public detailsOfRelevantEntities = computed((): EntityDetails[] => {
		let detailsOfEntities = this.detailsOfAllEntities.value;
		if (this.pricingFilter.value !== '') {
			detailsOfEntities = detailsOfEntities.filter((details) => {
				const configurationOfAllEntities =
					details.type === EntityTypes.STUDY ? this.configurationOfAllStudies.value : this.configurationOfAllOrganisations.value;
				const entity = configurationOfAllEntities.find((configuration) => details.id === configuration.id);
				if (!entity) {
					return false;
				}

				/* Show entities that:
				[1] have a pricing in line with the filter selection or
				[2] entities without pricing defined when the 'No pricing' filter is selected. */
				return entity.pricing === this.pricingFilter.value || (!entity.pricing && this.pricingFilter.value === '0');
			});
		}

		if (this.degreeFilter.value !== '') {
			detailsOfEntities = detailsOfEntities.filter(
				(details) => this.degreeFilter.value.toLowerCase() === details.degreeType.toLowerCase()
			);
		}

		return detailsOfEntities;
	});

	private detailsOfNonSelectedEntities = computed((): EntityDetails[] => {
		return this.detailsOfRelevantEntities.value.filter(
			(entityFromFullList) =>
				this.detailsOfSelectedEntities.value.find(
					(selectedEntity) => selectedEntity.id === entityFromFullList.id && selectedEntity.type === entityFromFullList.type
				) === undefined
		);
	});

	public isEntitySelected(detailsOfEntity: EntityDetails): boolean {
		return (
			this.detailsOfSelectedEntities.value.find(
				(selectedEntity) => selectedEntity.id === detailsOfEntity.id && selectedEntity.type === detailsOfEntity.type
			) !== undefined
		);
	}

	public retrieveEntityType(detailsOfEntity: EntityDetails): string {
		return this.isEntityTypeStudy(detailsOfEntity) ? 'Programme' : 'Organisation';
	}

	public isEntityTypeStudy(detailsOfEntity: EntityDetails): boolean {
		return detailsOfEntity.type === EntityTypes.STUDY;
	}

	public updateEntitySelection(detailsOfEntity: EntityDetails): void {
		if (this.isEntitySelected(detailsOfEntity)) {
			store.mutations.markEntityAsNotSelected(detailsOfEntity);
		} else {
			store.mutations.markEntityAsSelected(detailsOfEntity);
		}
	}

	public selectAllEntities(): void {
		this.detailsOfNonSelectedEntities.value.forEach((detailsOfEntity) => store.mutations.markEntityAsSelected(detailsOfEntity));
	}

	public deselectAllEntities(): void {
		store.mutations.markAllEntitiesAsNotSelected();
	}

	public setPricingFilterSelection(value: string): void {
		this.pricingFilter.value = value;
	}

	public setDegreeFilterSelection(value: string): void {
		this.degreeFilter.value = value;
	}
}
