import store from '@/store';

export default class ListingOverviewComponent {
	public get campaignEditorUrl(): string {
		return `${this.uniAdminUrl}/statistics/campaign/${this.campaignId}/edit.html#TabProducts*en-GB`;
	}

	private get campaignId(): number {
		return store.getters.campaignId();
	}

	private get uniAdminUrl(): string {
		return store.getters.uniAdminUrl();
	}
}
