/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent } from 'vue';
import EntitySelectionFilters from '@/presentation/components/entity-selection-filters/entity-selection-filters.vue';
import EntitySelectionComponent from '@/presentation/pages/entity-selection/entity-selection-class';

export default defineComponent({
	components: {
		EntitySelectionFilters: EntitySelectionFilters as Component
	},
	setup: () => {
		const component = new EntitySelectionComponent();

		return {
			postData: component.postData,
			detailsOfSelectedEntities: component.detailsOfSelectedEntities,
			doSelectedEntitiesHaveTheSameConfiguration: component.doSelectedEntitiesHaveTheSameConfiguration,
			detailsOfRelevantEntities: component.detailsOfRelevantEntities,
			isEntitySelected: component.isEntitySelected.bind(component),
			retrieveEntityType: component.retrieveEntityType.bind(component),
			isEntityTypeStudy: component.isEntityTypeStudy.bind(component),
			updateEntitySelection: component.updateEntitySelection.bind(component),
			selectAllEntities: component.selectAllEntities.bind(component),
			deselectAllEntities: component.deselectAllEntities.bind(component),
			setPricingFilterSelection: component.setPricingFilterSelection.bind(component),
			setDegreeFilterSelection: component.setDegreeFilterSelection.bind(component)
		};
	}
});
