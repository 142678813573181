import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "premiumLevel",
    style: _normalizeStyle({
			backgroundColor: _ctx.premium.color,
			color: _ctx.textColor
		})
  }, _toDisplayString(_ctx.premium.name || 'Basic'), 5 /* TEXT, STYLE */))
}