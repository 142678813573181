import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "Navigation",
    class: _normalizeClass([_ctx.isEntitySelectionScreen ? 'OnEntitySelectionScreen' : ''])
  }, [
    (_ctx.shouldNavigationButtonBeShown(_ctx.navigationOptionNext))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "DriverButton",
          disabled: _ctx.shouldNextBeDisabled,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.triggerNavigationButtonAction(_ctx.navigationOptionNext)))
        }, _toDisplayString(_ctx.getNavigationButtonTitle(_ctx.navigationOptionNext)), 9 /* TEXT, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    (_ctx.isEntitySelectionScreen && _ctx.isThereAtLeastOneEntityWithPricing)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "NavigatorButton",
          disabled: _ctx.shouldNextBeDisabled,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateToExistingPresetBasedSelection && _ctx.navigateToExistingPresetBasedSelection(...args)))
        }, " Set based on existing preset ", 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_ctx.shouldNavigationButtonBeShown(_ctx.navigationOptionBack))
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "NavigatorButton",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.triggerNavigationButtonAction(_ctx.navigationOptionBack)))
        }, _toDisplayString(_ctx.getNavigationButtonTitle(_ctx.navigationOptionBack)), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}