import { computed } from 'vue';
import { Continents } from '@/types/locations';
import store from '@/store';

export default class ContinentIndicatorComponent {
	public continents = computed((): Continents => {
		return store.getters.relevantContinentsGivenSearchQuery();
	});

	public setSelectedContinent(id: number): void {
		store.mutations.setSelectedContinent(id);
	}
}
