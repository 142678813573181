import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_Header = _resolveComponent("Header")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Navigation = _resolveComponent("Navigation")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.routeIsListing ? 'GeoListing' : 'GeoPage',
    class: "GeotargetingTool"
  }, [
    (_ctx.loadingInProgress)
      ? (_openBlock(), _createBlock(_component_LoadingAnimation, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", null, [
            (!_ctx.routeIsListing)
              ? (_openBlock(), _createBlock(_component_Header, {
                  key: 0,
                  style: _normalizeStyle(_ctx.layoutElementStyling)
                }, null, 8 /* PROPS */, ["style"]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_RouterView)
          ]),
          (!_ctx.routeIsListing)
            ? (_openBlock(), _createBlock(_component_Navigation, {
                key: 0,
                style: _normalizeStyle(_ctx.layoutElementStyling),
                class: "Navigation"
              }, null, 8 /* PROPS */, ["style"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
  ], 8 /* PROPS */, _hoisted_1))
}