import { Component, defineComponent } from 'vue';
import HeaderComponent from '@/presentation/components/header/header-class';
import Close from '@/presentation/components/close/close.vue';

export default defineComponent({
	components: {
		Close: Close as Component
	},
	setup: () => {
		const component = new HeaderComponent();

		return {
			title: component.title,
			subtitle: component.subtitle,
			isRouteOverview: component.isRouteOverview
		};
	}
});
