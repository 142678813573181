import OpenCloseAppHandler from '@/plugins/open-close-app-handler';

export default class ExitComponent {
	public closeApp(): void {
		OpenCloseAppHandler.closeApp();
	}

	public goBack(): void {
		OpenCloseAppHandler.abortClose();
	}
}
