import { computed, ref } from 'vue';
import { LoadingStates } from '@/types/i-loading';
import { ILoading, ILoadingMilestone, IRelevantLoadingMilestones } from '@/types/i-loading';
import store from '@/store';

export default class LoadingAnimationComponent {
	public progress = ref(0);
	private timeStart = 0;
	private interval: number | NodeJS.Timeout = 0;
	private loadingMilestones: ILoadingMilestone[] = [
		{ timePercentage: 0, progress: 0 },
		{ timePercentage: 0.25, progress: 25 },
		{ timePercentage: 0.5, progress: 45 },
		{ timePercentage: 0.75, progress: 65 },
		{ timePercentage: 1, progress: 80 },
		{ timePercentage: 1.5, progress: 90 },
		{ timePercentage: 2, progress: 95 },
		{ timePercentage: 3, progress: 98 }
	];

	public loadingText = computed((): string => {
		switch (this.loading.value.state) {
			case LoadingStates.SAVING:
				return 'Saving your geotargeting settings ...';
			case LoadingStates.STARTING:
				return 'Loading your geotargeting settings ...';
			default:
				return 'Loading ...';
		}
	});

	public savingInProgress = computed((): boolean => {
		return this.loading.value.state === LoadingStates.SAVING;
	});

	private loading = computed((): ILoading => {
		return store.getters.loading();
	});

	public mounted(): void {
		this.timeStart = Date.now();
		this.interval = setInterval(() => {
			this.updateProgress();
		}, 100);
	}

	public unmounted(): void {
		clearInterval(this.interval as NodeJS.Timeout);
	}

	private updateProgress(): void {
		// If the estimated time needed for saving is zero, gradually move to a hundred percent (used for smooth wrap-up at the end).
		if (this.loading.value.estimatedTimeNeededForSaving === 0) {
			this.progress.value = (this.progress.value + 100) / 2;
			return;
		}

		const progressIndicator = this.calculateElapsedPercentageOfExpectedTimeNeeded();
		const milestones = this.retrieveRelevantLoadingMilestones(progressIndicator);
		if (!milestones) {
			return;
		}

		this.calculateProgressWithMilestones(milestones, progressIndicator);
	}

	private calculateElapsedPercentageOfExpectedTimeNeeded(): number {
		const totalTimeElapsed = Date.now() - this.timeStart;
		return totalTimeElapsed / this.loading.value.estimatedTimeNeededForSaving;
	}

	private retrieveRelevantLoadingMilestones(progressIndicator: number): IRelevantLoadingMilestones | false {
		const nextMilestoneIndex = this.loadingMilestones.findIndex((item) => item.timePercentage > progressIndicator);
		if (nextMilestoneIndex === -1) {
			this.progress.value = this.loadingMilestones[this.loadingMilestones.length - 1].progress;
			return false;
		}

		return {
			previous: this.loadingMilestones[nextMilestoneIndex - 1],
			next: this.loadingMilestones[nextMilestoneIndex]
		};
	}

	private calculateProgressWithMilestones(milestones: IRelevantLoadingMilestones, progressIndicator: number): void {
		const timePercentageSinceLastMilestone = progressIndicator - milestones.previous.timePercentage;
		const maxTimePercentageTowardsNextMilestone = milestones.next.timePercentage - milestones.previous.timePercentage;
		const timePercentageBetweenMilestones = timePercentageSinceLastMilestone / maxTimePercentageTowardsNextMilestone;
		const progressSincePreviousMilestone = (milestones.next.progress - milestones.previous.progress) * timePercentageBetweenMilestones;
		this.progress.value = milestones.previous.progress + progressSincePreviousMilestone;
	}
}
