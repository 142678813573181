import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "continent-indicator" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.continents, (continent, id) => {
      return (_openBlock(), _createElementBlock("li", {
        key: continent.name,
        class: _normalizeClass(["continentListItem", { 'is-selected': continent.selected }]),
        onClick: ($event: any) => (_ctx.setSelectedContinent(id))
      }, _toDisplayString(continent.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}