import { computed, ref } from 'vue';
import { MainFlowRouteNames, RemainingRouteNames } from '@/types/i-router';
import { NavigationOptions } from '@/types/navigation-options';
import { EntityDetails } from '@/types/entities';
import { Entity } from '@/types/postData';
import SetCampaignDataHandler from '@/plugins/set-campaign-data-handler';
import store from '@/store';

export default class NavigationComponent {
	public saveText = 'Save';
	public lastRouteName = ref('');

	public get navigationOptionBack(): NavigationOptions {
		return NavigationOptions.BACK;
	}

	public get navigationOptionNext(): NavigationOptions {
		return NavigationOptions.NEXT;
	}

	private get navigateLabels(): { [routeKey in MainFlowRouteNames]: string } {
		return {
			[MainFlowRouteNames.ENTITY_SELECTION]: 'Select studies and organisations',
			[MainFlowRouteNames.MANAGEMENT]: 'Target countries',
			[MainFlowRouteNames.PRICING_SELECTION]: 'Select a pricing product',
			[MainFlowRouteNames.OVERVIEW]: 'Go to overview'
		};
	}

	private get navigateOptionsOmitted(): {
		[routeKey in MainFlowRouteNames]?: { [navKey in NavigationOptions]?: boolean };
	} {
		return {
			[MainFlowRouteNames.ENTITY_SELECTION]: {
				[NavigationOptions.BACK]: true
			}
		};
	}

	private get navigateOptionsExceptions(): {
		[routeKey in MainFlowRouteNames]?: { [navKey in NavigationOptions]?: () => void };
	} {
		return {
			[MainFlowRouteNames.ENTITY_SELECTION]: {
				[NavigationOptions.NEXT]: this.navigateToCountryTargeting.bind(this)
			},
			[MainFlowRouteNames.OVERVIEW]: {
				[NavigationOptions.NEXT]: SetCampaignDataHandler.setCampaignData.bind(SetCampaignDataHandler)
			}
		};
	}

	private get allRouteNames(): MainFlowRouteNames[] {
		return Object.values(MainFlowRouteNames);
	}

	private get allRouteNamesOnPresetSelectionScreen(): (MainFlowRouteNames | RemainingRouteNames)[] {
		return [MainFlowRouteNames.ENTITY_SELECTION, RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION];
	}

	public shouldNextBeDisabled = computed((): boolean => {
		return this.noEntitiesSelectedOnEntitySelectionPage.value || this.noPricingSelectedOnPricingSelectionPage.value;
	});

	public isThereAtLeastOneEntityWithPricing = computed((): boolean => {
		return store.getters.isThereAtLeastOneEntityWithPricing();
	});

	public currentRouteName = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});

	public isEntitySelectionScreen = computed((): boolean => {
		return this.currentRouteName.value === (MainFlowRouteNames.ENTITY_SELECTION as string);
	});

	private isPresetSelectionScreen = computed((): boolean => {
		return this.currentRouteName.value === (RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION as string);
	});

	private noEntitiesSelectedOnEntitySelectionPage = computed((): boolean => {
		const relevantRoute = this.isLeaveAction() ? this.lastRouteName.value : this.currentRouteName.value;
		return relevantRoute === (MainFlowRouteNames.ENTITY_SELECTION as string) && this.detailsOfSelectedEntities.value.length === 0;
	});

	private noPricingSelectedOnPricingSelectionPage = computed((): boolean => {
		const relevantRoute = this.isLeaveAction() ? this.lastRouteName.value : this.currentRouteName.value;
		return (
			relevantRoute === (MainFlowRouteNames.PRICING_SELECTION as string) &&
			(!this.doSelectedEntitiesHaveTheSamePricingSelected.value || !this.configurationOfSelectedEntities.value[0].pricing)
		);
	});

	private detailsOfSelectedEntities = computed((): EntityDetails[] => {
		return store.getters.detailsOfSelectedEntities();
	});

	private configurationOfSelectedEntities = computed((): Entity[] => {
		return store.getters.configurationOfSelectedEntities();
	});

	private doSelectedEntitiesHaveTheSameTargeting = computed((): boolean => {
		return store.getters.doSelectedEntitiesHaveTheSameTargeting();
	});

	private doSelectedEntitiesHaveTheSamePricingSelected = computed((): boolean => {
		const entities = this.configurationOfSelectedEntities.value;

		return entities.find((entity) => entity.pricing !== entities[0].pricing) === undefined;
	});

	private searchQuery = computed((): string => {
		return store.getters.searchQueryCountries();
	});

	public updatePreviousRouteName(to: string, from: string): void {
		this.lastRouteName.value = from;
	}

	public shouldNavigationButtonBeShown(navigationOption: NavigationOptions): boolean {
		const omittedOption = this.navigateOptionsOmitted[this.currentRouteName.value];
		if (!omittedOption) {
			return true;
		}

		return omittedOption[navigationOption] !== true;
	}

	public triggerNavigationButtonAction(navigationOption: NavigationOptions): void {
		if (this.searchQuery.value.length) {
			store.mutations.updateSearchQueryCountries('');
		}

		if (this.isPresetSelectionScreen.value) {
			this.triggerNavigationButtonActionForPresetSelectionScreen(navigationOption);
			return;
		}

		const exceptionOption = this.navigateOptionsExceptions[this.currentRouteName.value];
		if (!exceptionOption || !exceptionOption[navigationOption]) {
			const targetIndex = this.getTargetIndex(navigationOption);
			this.navigate(this.allRouteNames[targetIndex]);
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		exceptionOption[navigationOption]();
	}

	public getNavigationButtonTitle(navigationOption: NavigationOptions): string {
		const targetIndex = this.getTargetIndex(navigationOption);

		if (this.isLeaveAction()) {
			return this.getNavigationButtonTitleForPreviousRoute(navigationOption);
		}

		if (targetIndex >= this.allRouteNames.length) {
			return this.saveText;
		}

		return this.navigateLabels[this.allRouteNames[targetIndex]];
	}

	public navigateToExistingPresetBasedSelection(): void {
		this.navigate(RemainingRouteNames.EXISTING_PRESET_BASED_SELECTION);
	}

	private triggerNavigationButtonActionForPresetSelectionScreen(navigationOption: NavigationOptions): void {
		if (navigationOption === NavigationOptions.BACK) {
			this.navigate(MainFlowRouteNames.ENTITY_SELECTION);
			return;
		}

		void SetCampaignDataHandler.setCampaignDataBasedOnPreset();
	}

	private isLeaveAction(navigationOption = NavigationOptions.NEXT): boolean {
		const targetIndex = this.getTargetIndex(navigationOption);
		const backButtonLeaveAction = targetIndex === -2 && navigationOption === NavigationOptions.BACK;
		const nextButtonLeaveAction = targetIndex === 0 && navigationOption === NavigationOptions.NEXT;
		return backButtonLeaveAction || nextButtonLeaveAction;
	}

	private getNavigationButtonTitleForPreviousRoute(navigationOption: NavigationOptions): string {
		const routeToCheck = this.isPresetSelectionScreen.value ? this.currentRouteName.value : this.lastRouteName.value;
		const allRouteNames = this.isPresetSelectionScreen.value ? this.allRouteNamesOnPresetSelectionScreen : this.allRouteNames;

		const indexCorrection = navigationOption === NavigationOptions.NEXT ? 1 : -1;
		const routeIndex = allRouteNames.indexOf(routeToCheck as MainFlowRouteNames | RemainingRouteNames) + indexCorrection;
		if (routeIndex === allRouteNames.length) {
			return this.saveText;
		}

		return this.navigateLabels[allRouteNames[routeIndex]] as string;
	}

	private navigateToCountryTargeting(): void {
		if (!this.doSelectedEntitiesHaveTheSameTargeting.value) {
			store.mutations.resetCountryPremiumsForSelectedEntities();
		}

		this.navigate(MainFlowRouteNames.MANAGEMENT);
	}

	private getTargetIndex(navigationOption: NavigationOptions): number {
		const step = navigationOption === NavigationOptions.NEXT ? 1 : -1;
		return this.allRouteNames.indexOf(this.currentRouteName.value as MainFlowRouteNames) + step;
	}

	private navigate(name: MainFlowRouteNames | RemainingRouteNames): void {
		store.mutations.setCurrentRoute(name);
	}
}
