import SetCampaignDataHandler from '@/plugins/set-campaign-data-handler';
import OpenCloseAppHandler from '@/plugins/open-close-app-handler';

export default class ErrorComponent {
	public setCampaignData(): void {
		void SetCampaignDataHandler.setCampaignData();
	}

	public closeApp(): void {
		OpenCloseAppHandler.closeApp();
	}
}
