import { LoadingStates } from '@/types/i-loading';
import { PostData } from '@/types/postData';
import { INetworkInfo } from '@/types/i-loading';
import store from '@/store';

class LoadingHandler {
	public beginDisplayingSavingProgress(): void {
		this.storeLoadingTime();
		this.markSavingStarted();
	}

	public wrapUpSavingProgressDisplayProcess(): void {
		// Reset loading time to communicate that the saving process has finished.
		store.mutations.setLoadingTime(0);

		// Hide the saving animation with a small delay, so that the loading progress bar can still fill up to the end.
		setTimeout(() => {
			this.finishDisplayingSavingProgress();
		}, 500);
	}

	private markSavingStarted(): void {
		store.mutations.setLoadingState(LoadingStates.SAVING);
	}

	private finishDisplayingSavingProgress(): void {
		store.mutations.setLoadingState(LoadingStates.INACTIVE);
	}

	private retrievePostData(): PostData {
		return store.getters.postData();
	}

	private storeLoadingTime(): void {
		const expectedTimeForSaving = this.calculateExpectedTimeForSaving();
		store.mutations.setLoadingTime(expectedTimeForSaving);
	}

	private calculateExpectedTimeForSaving(): number {
		const selectedEntities = store.getters.detailsOfSelectedEntities();
		const relevantIds = store.getters.relevantCampaignEntityConfigItemIds();

		const countRemoval = relevantIds.length / 3;
		const countAdding = selectedEntities.length;
		const speed = this.retrieveNetworkSpeed();

		// The following formulas are the result of testing & timing the duration of several requests (with varying configuration sizes).
		const expectedDurationOfRemoval = (500 + countRemoval * 10) * (1 / speed) + 180 * countRemoval + 200;
		const expectedDurationOfAdding = (500 + countAdding * 10) * (1 / speed) + 360 * countRemoval + 200;
		const expectedDurationOfGeoSaving = (5000 + countAdding * 150) * (1 / (speed + 3)) + 1500;

		return Math.floor(expectedDurationOfRemoval + expectedDurationOfAdding + expectedDurationOfGeoSaving);
	}

	private retrieveNetworkSpeed(): number {
		const defaultNetworkSpeed = 4;
		const networkInfo = window.navigator as INetworkInfo | undefined;
		return networkInfo?.connection?.downlink ? networkInfo.connection.downlink : defaultNetworkSpeed;
	}
}

export default new LoadingHandler();
