import { Entity } from '@/types/postData';
import { EntityTypes } from '@/types/entities';
import { CampaignEntityConfigItem, PricingProduct, RawPricingProduct, RawAttachedPricingProduct, RawPricingProductsCollection }
	from '@/types/pricing';
import { acceptablePricingProductNames, nonReferralPricingProducts } from '@/static/details-of-specific-pricing-products';
import SchedulerRequestsHandler from '@/plugins/scheduler-requests-handler';
import ServiceLayerRequestsHandler from '@/plugins/service-layer-requests-handler';
import store from '@/store';

class PricingHandler {
	public async savePricingProducts(): Promise<void> {
		if (SchedulerRequestsHandler.shouldChangesGoThroughScheduler()) {
			await SchedulerRequestsHandler.savePricingProducts();
		} else {
			/* Take the necessary steps to remove and add pricing products; also update the config items in the store
			in case something goes wrong and the user retries the saving process afterwards. */
			await ServiceLayerRequestsHandler.removeExistingPricingProducts();
			store.mutations.setCampaignEntityConfigItems([]);
			const configItems = await ServiceLayerRequestsHandler.savePricingProducts();
			store.mutations.setCampaignEntityConfigItems(configItems);
		}

		await this.onNonZeroPricingProductActivateTableauReporting();
	}

	public async retrieveAllPricingProducts(): Promise<PricingProduct[]> {
		const rawPricingProductsData = await ServiceLayerRequestsHandler.retrieveRawPricingProductsData();
		const pricingProducts = this.convertRawPricingProductsIntoExpectedFormat(rawPricingProductsData);
		return this.sortPricingProducts(pricingProducts);
	}

	public async retrieveRawAttachedPricingProductsData(): Promise<RawAttachedPricingProduct[]> {
		if (SchedulerRequestsHandler.shouldChangesGoThroughScheduler()) {
			const data = await SchedulerRequestsHandler.retrieveRawAttachedPricingProductsData();
			return data.pricing.map((item) => ({
				sp_id: item.entityID,
				type: item.entityType,
				product_id: item.productID
			}));
		}

		const result = await ServiceLayerRequestsHandler.retrieveRawAttachedPricingProductsData();
		return result.campaignEntityConfigs;
	}

	public attachPricingProductsForEntities(
		rawAttachedPricingProductsData: RawAttachedPricingProduct[],
		entities: Entity[],
		type: EntityTypes
	): CampaignEntityConfigItem[] {
		const campaignEntityConfigItems: CampaignEntityConfigItem[] = [];

		entities.forEach((entity) => {
			const productsForEntity = rawAttachedPricingProductsData.filter((item) => {
				if (parseInt(item.sp_id) !== entity.id || item.type !== (type as string)) {
					return false;
				}

				// Only keep track of campaign entity config ids when they are available (when targeting the SL and not the Scheduler).
				if (item.id) {
					campaignEntityConfigItems.push({
						id: item.id,
						entity_id: item.sp_id,
						type
					});
				}

				return true;
			});

			const referralProductForEntity = productsForEntity.find(
				(item) => !nonReferralPricingProducts.includes(item.product_id)
			);
			if (!referralProductForEntity) {
				return;
			}

			entity.pricing = referralProductForEntity.product_id;
		});

		return campaignEntityConfigItems;
	}

	private async onNonZeroPricingProductActivateTableauReporting(): Promise<void> {
		const configurationOfEntities = store.getters.configurationOfAllEntities();
		const zeroPricingId = '44';
		const irrelevantPricingIds = nonReferralPricingProducts.concat([zeroPricingId, '']);
		const entityWithNonZeroPricing = configurationOfEntities.find(
			(item) => irrelevantPricingIds.includes(item.pricing) === false
		);
		if (!entityWithNonZeroPricing) {
			return;
		}

		await ServiceLayerRequestsHandler.activateTableauReportingForCampaign();
	}

	private convertRawPricingProductsIntoExpectedFormat(rawPricingProductsData: RawPricingProductsCollection): PricingProduct[] {
		const pricingProducts: PricingProduct[] = [];
		const pricingProductIds = Object.keys(rawPricingProductsData);
		pricingProductIds.forEach((id) => {
			const product = this.createPricingProductFromRawData(rawPricingProductsData[id]);
			if (!product) {
				return;
			}

			pricingProducts.push(product);
		});

		return pricingProducts;
	}

	private createPricingProductFromRawData(rawData: RawPricingProduct): PricingProduct | undefined {
		if (!acceptablePricingProductNames.includes(rawData.name)) {
			return;
		}

		return {
			product_id: rawData.id,
			name: rawData.name,
			description: rawData.description,
			pricing_groups: rawData.pricing_groups.split(',')
		};
	}

	private sortPricingProducts(products: PricingProduct[]): PricingProduct[] {
		return products.sort((a, b) => (a.description > b.description ? 1 : -1));
	}
}

export default new PricingHandler();
