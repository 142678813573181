import { computed, ref, Ref } from 'vue';
import { Premiums } from '@/types/premiums';
import store from '@/store';
import { PricingProduct } from '@/types/pricing';

export default class CountryTargetingSummaryComponent {
	public selectedTargetingTab = ref(0);

	constructor(
		private emit: (name: string, value: number) => void,
		public id: number,
		public tabSelected: Ref<boolean>,
		public numCountriesPerPremium: Ref<{ [key: string]: number }>,
		public countriesPerPremium: Ref<{ [key: string]: number[] }>,
		public pricing: Ref<string | null>
	) {}

	public premiumStates = computed((): Premiums => {
		return store.getters.premiumStates();
	});

	public shouldPricingBeShown = computed((): boolean => {
		return this.pricing.value !== null;
	});

	public countryTargetingDetails = computed((): string => {
		const details = this.numCountriesPerPremium.value;
		return `${details.b} basic, ${details.lp} light premium, ${details.p} premium, and ${details.sp} super premium`;
	});

	public countriesOfSelectedTargetingTab = computed((): number[] => {
		const keys = Object.keys(this.premiumStates.value);
		const selectedKey = keys[this.selectedTargetingTab.value];
		return this.countriesPerPremium.value[selectedKey];
	});

	public pricingValue = computed((): string => {
		if (!this.pricing.value) {
			return 'not set';
		}

		const pricingProducts = this.referralPricingProducts.value;
		const relevantPricingProduct = pricingProducts.find((product) => product.product_id === this.pricing.value);
		if (!relevantPricingProduct?.description) {
			return '';
		}

		const pricingNumber = relevantPricingProduct.description.split(' ').pop();
		return pricingNumber ? pricingNumber : '';
	});

	private referralPricingProducts = computed((): PricingProduct[] => {
		return store.getters.referralPricingProducts();
	});

	public toggleOverallTab(): void {
		this.emit('tabSelectionChanged', this.tabSelected.value ? -1 : this.id);
	}

	public setTargetingTab(tabIndex: number): void {
		this.selectedTargetingTab.value = tabIndex;
	}
}
