import { computed, ref } from 'vue';
import { CountriesPerPremiumGroup } from '@/types/premiums';
import store from '@/store';

export default class ExistingPresetBasedSelectionComponent {
	public selectedTab = ref(-1);

	public selectedPresetIndex = computed((): number => {
		return store.getters.selectedPresetIndex();
	});

	public countriesPerPremiumGroups = computed((): CountriesPerPremiumGroup[] => {
		return store.getters.countriesPerPremiumGroups();
	});

	public mounted(): void {
		this.setSelectedPresetIndex(0);
	}

	public setSelectedPresetIndex(index: number): void {
		store.mutations.setSelectedPresetIndex(index);
	}

	public toggleTab(tabIndex: number): void {
		this.selectedTab.value = this.selectedTab.value === tabIndex ? -1 : tabIndex;
	}
}
