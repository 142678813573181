import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "Countries" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: country.name
      }, [
        _createTextVNode(_toDisplayString(country.name), 1 /* TEXT */),
        (_ctx.showComma(index))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, ","))
          : _createCommentVNode("v-if", true),
        _createTextVNode("  ")
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}