import { SessionTokenRequestSigner, UserSessionCookieManager } from '@studyportals/client-internal-platform-sso';
import { BaseSuperAgentRequestFactory, ISuperAgentRequestFactory, SignedRequestSender } from '@studyportals/mb-platform-http-requests';

export default {
	/**
	 * Make a signed post request (with Cognito authentication included), which exact
	 * purpose depends on the url and data that are passed along.
	 *
	 * @param {Object} cognito
	 * @param {String} url
	 * @param {*} data
	 * @return Promise
	 */

	signedPostRequest(cognito, url, data): Promise<void> {
		const factory = this.createSuperAgentRequestFactory();
		const sessionProvider = this.createUserSessionCookieManager(cognito);
		const signer = this.createSessionTokenRequestSigner(sessionProvider);
		const sender = this.createSignedRequestSender(signer);

		const request = factory.post(url).send(data);

		return sender.send(request).catch((error) => console.error(error.message));
	},

	/**
	 * Create a super agent request factory.
	 *
	 * @return {ISuperAgentRequestFactory}
	 */

	createSuperAgentRequestFactory(): ISuperAgentRequestFactory {
		return new BaseSuperAgentRequestFactory();
	},

	/**
	 * Create a session provider.
	 *
	 * @param {Object} cognito
	 * @return {UserSessionCookieManager}
	 */

	createUserSessionCookieManager(cognito): UserSessionCookieManager {
		return new UserSessionCookieManager(cognito.clientId, cognito.poolId, cognito.domain);
	},

	/**
	 * Create a request signer.
	 *
	 * @param {UserSessionCookieManager} sessionProvider
	 * @return {SessionTokenRequestSigner}
	 */

	createSessionTokenRequestSigner(sessionProvider): SessionTokenRequestSigner {
		return new SessionTokenRequestSigner(sessionProvider);
	},

	/**
	 * Create a request sender.
	 *
	 * @param {SessionTokenRequestSigner} signer
	 * @return {SignedRequestSender}
	 */

	createSignedRequestSender(signer): SignedRequestSender {
		return new SignedRequestSender(signer);
	}
};
