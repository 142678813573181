import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "countryListItem" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumLabel = _resolveComponent("PremiumLabel")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("input", {
      class: "Checkbox",
      type: "checkbox",
      id: _ctx.country.name + '_' + _ctx.id,
      checked: _ctx.isChecked,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.country.name + '_' + _ctx.id,
      class: "countryLabel"
    }, [
      _createVNode(_component_PremiumLabel, {
        premium: _ctx.premium,
        class: "PremiumLabel"
      }, null, 8 /* PROPS */, ["premium"]),
      _createElementVNode("span", {
        class: _normalizeClass(["CountryItem", { 'is-selected': _ctx.isChecked }])
      }, _toDisplayString(_ctx.country.name), 3 /* TEXT, CLASS */)
    ], 8 /* PROPS */, _hoisted_3)
  ]))
}