import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
		'RouterViewWrapper',
		{ Hidden: !_ctx.readyToBeShown }
	])
  }, [
    _createVNode(_Transition, {
      name: _ctx.transitionName,
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("div", { key: _ctx.CurrentComponent }, [
          (_ctx.readyToBeShown)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CurrentComponent), _mergeProps({ key: 0 }, _ctx.router.props, { class: "RouterView" }), null, 16 /* FULL_PROPS */))
            : _createCommentVNode("v-if", true)
        ]))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["name"])
  ], 2 /* CLASS */))
}