/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent, toRef } from 'vue';
import CountryComponent from '@/presentation/components/country/country-class';
import PremiumLabel from '@/presentation/components/premium-label/premium-label.vue';

export default defineComponent({
	components: {
		PremiumLabel: PremiumLabel as Component
	},
	props: {
		id: { type: String, default: '' }
	},
	setup: (props) => {
		const id = toRef(props, 'id');
		const component = new CountryComponent(id);

		return {
			id,
			country: component.country,
			premium: component.premium,
			isChecked: component.isChecked,
			toggle: component.toggle.bind(component)
		};
	}
});
