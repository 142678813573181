/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { defineComponent, onMounted, watch } from 'vue';
import RouterViewComponent from '@/presentation/components/router-view/router-view-class';

export default defineComponent({
	setup: () => {
		const component = new RouterViewComponent();

		onMounted(component.mounted.bind(component));

		watch(component.currentRoute, component.setTransitionName.bind(component));

		return {
			readyToBeShown: component.readyToBeShown,
			transitionName: component.transitionName,
			router: component.router,
			CurrentComponent: component.CurrentComponent
		};
	}
});
