import { Component } from 'vue';

enum MainFlowRouteNames {
	ENTITY_SELECTION = 'entitySelection',
	MANAGEMENT = 'management',
	PRICING_SELECTION = 'pricingSelection',
	OVERVIEW = 'overview'
}

enum RemainingRouteNames {
	FINAL_SUCCESS = 'finalSuccess',
	FINAL_ERROR = 'finalError',
	FINAL_EXIT = 'finalExit',
	STUDY_LISTING_OVERVIEW = 'StudyListingOverview',
	ORGANISATION_LISTING_OVERVIEW = 'OrganisationListingOverview',
	EXISTING_PRESET_BASED_SELECTION = 'ExistingPresetBasedSelection',
	DEFAULT = 'default'
}

type TRouteNames = MainFlowRouteNames | RemainingRouteNames;

interface IRouter {
	current: TRouteNames;
	props: {
		[key: string]: any;
	};
}

type TRoutes = {
	[key in TRouteNames]: Component;
};

export { IRouter, TRoutes, MainFlowRouteNames, RemainingRouteNames, TRouteNames };
