/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, defineComponent } from 'vue';
import Final from '@/presentation/pages/final/final/final.vue';
import ErrorComponent from '@/presentation/pages/final/error/error-class';

export default defineComponent({
	components: {
		Final: Final as Component
	},
	props: {
		savingError: { type: Boolean, default: false }
	},
	setup: (props) => {
		const component = new ErrorComponent();

		return {
			savingError: props.savingError,
			setCampaignData: component.setCampaignData.bind(component),
			closeApp: component.closeApp.bind(component)
		};
	}
});
