import state from './state';
import getters from '@/store/getters';
import EntityDetailsHandler from '@/plugins/entity-details-handler';
import { PremiumChangeWithIdAsNumber } from '@/types/premiums';
import { PostData, Entity, EntityWithIdAsString } from '@/types/postData';

import { IMutations } from '@/types/store/i-mutations';

const mutations: IMutations = {
	setCampaignData: (data) => {
		if (!data.studies) data.studies = [];
		if (!data.organisations) data.organisations = [];

		const convertItem = (item: EntityWithIdAsString): Entity => {
			const itemWithIdAsNumber: Entity = {
				id: parseInt(item.id, 10),
				targeted_countries: item.targeted_countries
			};
			return itemWithIdAsNumber;
		};

		const campaignData: PostData = {
			campaign_id: data.campaign_id,
			expiry_date: data.expiry_date,
			update_date: data.update_date,
			studies: data.studies.map(convertItem),
			organisations: data.organisations.map(convertItem)
		};

		mutations.updateCampaignData(campaignData);
	},
	updateCampaignData: (data) => {
		state.postData = data;
		state.originalData = JSON.parse(JSON.stringify(data));
	},
	setDetailsOfAllEntities: (data) => {
		state.detailsOfAllEntities = data;
	},
	setCountryChecked: (data) => {
		const id = data.id.toString();
		state.countries[id].checked = data.checked;
	},
	setSelectedContinent: (continentId) => {
		const conId = continentId.toString();

		Object.keys(state.continents).forEach((id) => {
			state.continents[id].selected = id === conId;
		});
	},
	setCountryPremiumForEntity: (entities, data) => {
		entities.forEach((entity, index) => {
			const levels = Object.keys(entity.targeted_countries);

			levels.forEach((level) => {
				const countryList = entity.targeted_countries[level];
				const countrySet: Set<number> = new Set(countryList as number[]);
				countrySet.delete(data.id);
				entities[index].targeted_countries[level] = [...countrySet];
			});
		});

		if (data.level === 'b') {
			return;
		}

		entities.forEach((_, index) => {
			if (!entities[index].targeted_countries[data.level]) {
				entities[index].targeted_countries[data.level] = [];
			}

			(entities[index].targeted_countries[data.level] as number[]).push(data.id);
		});
	},
	setCountryPremium: (data) => {
		const dataWithIdAsNumber: PremiumChangeWithIdAsNumber = {
			id: parseInt(data.id, 10),
			level: data.level
		};
		mutations.setCountryPremiumForEntity(getters.configurationOfSelectedEntities(), dataWithIdAsNumber);
	},
	resetCountryPremiumsForSelectedEntities: () => {
		state.detailsOfSelectedEntities.forEach((selectedEntity) => {
			const entity = EntityDetailsHandler.retrieveSelectedEntity(state.postData, selectedEntity);
			if (!entity) {
				return;
			}

			const premiumStates = Object.keys(state.premiumStates);
			premiumStates.forEach((premiumState) => {
				if (premiumState === 'b') {
					return;
				}

				entity.targeted_countries[premiumState] = [];
			});
		});
	},
	markEntityAsSelected: (entity) => {
		state.detailsOfSelectedEntities.push(entity);
	},
	markEntityAsNotSelected: (entity) => {
		const index = state.detailsOfSelectedEntities.findIndex(
			(selectedEntity) => selectedEntity.id === entity.id && selectedEntity.type === entity.type
		);
		if (index === -1) {
			return;
		}

		state.detailsOfSelectedEntities.splice(index, 1);
		mutations.resetPostData();
	},
	markAllEntitiesAsNotSelected: () => {
		state.detailsOfSelectedEntities = [];
		mutations.resetPostData();
	},
	setPricingForSelectedEntities: (pricing) => {
		state.detailsOfSelectedEntities.forEach((selectedEntity) => {
			const entity = EntityDetailsHandler.retrieveSelectedEntity(state.postData, selectedEntity);
			if (!entity) {
				return;
			}

			entity.pricing = pricing;
		});

		// Ensure that the changes in postData will be acknowledged by Vuex and will result in Vuex getters being updated.
		state.postData = JSON.parse(JSON.stringify(state.postData));
	},
	setSelectedPresetIndex: (index) => {
		state.selectedPresetIndex = index;
	},
	resetPostData: () => {
		// Reset postData in case original data was overwritten with all countries as basic for selected entities with different targeting.
		state.postData = JSON.parse(JSON.stringify(state.originalData));
	},
	setPricingProducts: (products) => {
		state.pricingProducts = products;
	},
	setCampaignEntityConfigItems: (campaignEntityConfigItems) => {
		state.campaignEntityConfigItems = campaignEntityConfigItems;
	},
	updateSearchQueryCountries: (query) => {
		state.searchQueryCountries = query;

		const continentId = getters.selectedContinentId();
		const relevantContinents = getters.relevantContinentsGivenSearchQuery();
		if (relevantContinents[continentId] !== undefined) {
			return;
		}

		const relevantContinentIds = Object.keys(relevantContinents);
		if (relevantContinentIds.length === 0) {
			return;
		}

		mutations.setSelectedContinent(parseInt(relevantContinentIds[0]));
	},
	setStudyId: (id) => {
		state.studyId = id;
	},
	setOrganisationId: (id) => {
		state.organisationId = id;
	},
	setCampaignId: (id) => {
		state.campaignId = id;
	},
	setCampaignStartDate: (timeStamp) => {
		state.campaignStartDate = timeStamp;
	},
	setLoadingState: (newState) => {
		state.loading.state = newState;
	},
	setLoadingTime: (time) => {
		state.loading.estimatedTimeNeededForSaving = time;
	},
	setCurrentRoute: (name, props = {}) => {
		state.router.current = name;
		state.router.props = props;
	},
	markAsInitialised: () => {
		state.initialised = true;
	}
};

export default mutations;
