import { computed } from 'vue';
import { Premiums } from '@/types/premiums';
import store from '@/store';

export default class OverviewListComponent {
	public get premiumStates(): Premiums {
		return store.getters.premiumStates();
	}

	public countriesPerPremium = computed((): { [key: string]: number[] } => {
		return store.getters.countriesPerPremium();
	});

	public numCountriesPerPremium = computed((): { [key: string]: number } => {
		return store.getters.numCountriesPerPremium();
	});
}
